import React,{useState,useEffect} from "react";
import Cookies from "universal-cookie"; 
import Login from "./pages/Login.jsx";
import DashBoard from "./pages/DashBoard.jsx";
import {Routes,Route} from "react-router-dom";
import api from "./Api.js";

export default function App() {

  window.swal = require("sweetalert2");
  window.cookies = new Cookies();
   
  const [update,setUpdate] = useState(0);
  const [configuration,setConfiguration] = useState({});
  window.configuration = configuration;
  
  const getConfiguration = (() => {

    api.getConfiguration().then(function(response){

      setConfiguration(response.data);

    });

  });

  useEffect(() => {

    getConfiguration();

  },[]);
  
  window.update = (() => { setUpdate(update+1); });

  return (
    <div className="App">
      {

        (window.user === undefined) &&
        <Login />

      }  
      {
       
        (window.user !== undefined) &&
        <Routes>
          <Route exact path="*" element={<DashBoard />}/> 
        </Routes>
         
      }
      
    </div>
  );


}
