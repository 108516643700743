import React,{useState,useEffect} from "react";
import api from "../../Api.js";
import ClipLoader from "react-spinners/ClipLoader";
import {Button,Modal} from "reactstrap";

export default function Coins(){
//teste
    const [load,setLoad] = useState(false);

    const [openModal,setOpenModal] = useState(false);
    const [dialog,setDialog] = useState({});
    const [purchase,setPurchase] = useState([]);
    
    const listPurchase = (() => {

        setLoad(false);
        api.listPurchase().then(async (res) => {

            setLoad(true);
            setPurchase(res.data);

        });

    });
  
 
    const remover = ((id) => {

        window.swal.fire({
            position:"center",
            toast: true,
            title: 'Você deseja remover a compra?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar"
        }).then((result) => {
      
            if(result.value){
      
                api.deletePurchase(id).then((res) => {
      
                  const Toast = window.swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', window.swal.stopTimer)
                      toast.addEventListener('mouseleave', window.swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({icon:'success',title:'A compra foi removida.'});
                  listPurchase();
                  
                }).catch(error => {
      
                    if(error !== undefined){
      
                        if(error.response !== undefined){
      
                            if (error.response.data !== undefined){
      
                                if(error.response.data.msg !== undefined){
                                
                                    const Toast = window.swal.mixin({
                                        toast: true,
                                        position: 'top',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', window.swal.stopTimer)
                                            toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                        }
                                    })
                                    
                                    Toast.fire({icon:'error',title:error.response.data.msg});
                                  
                                }
      
                            }
      
                        }
      
                    }
      
                });
      
            }
      
        });
      
    });
       
    const postPurchase = (() => {

        console.log('postPurchase');
        var obj = JSON.parse(JSON.stringify(dialog)); 
 

        console.log(obj);
        
        api.postPurchase(obj).then(async (res) => { 

            setDialog(res.data);
            setOpenModal(false);
            listPurchase();

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'success',title:'A compra foi cadastrada.'});
            
        }).catch((error) => {

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:error.response.data.msg});

        });

    });

    const putPurchase = (() => {

        console.log('postCoin');
        var obj = JSON.parse(JSON.stringify(dialog)); 

        obj.status = parseInt(obj.status);
        obj.buy = parseInt(obj.buy);
        obj.sell = parseInt(obj.sell);
        obj.tax_buy = parseInt(obj.tax_buy);
        obj.tax_sell = parseInt(obj.tax_sell);

        console.log(obj);
        
        api.putPurchase(obj.id,obj).then(async (res) => { 

            setDialog(res.data);
            setOpenModal(false);
            listPurchase();
            
            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'success',title:'As alterações foram salvas.'});
            
        }).catch((error) => {

            console.error(error);
            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:error.response.data.msg});

        });

    });

    useEffect(() => {

        listPurchase();
        return (() => { });

    },[]);

    return (<React.Fragment>
                
        <div className="container-fluid py-4">
            <div className="d-sm-flex justify-content-between">
                <div></div>
                <div className="d-flex"> 
                    <button onClick={() => { setOpenModal(true); setDialog({}); }}className="btn btn-icon bg-gradient-success ms-2"><i className="fas fa-plus"></i> Cadastrar compra</button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Compras de moedas</h5>
                        </div>
                        <div className="table-responsive">
                            <table className="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">#</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Exchange</th> 
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Coin</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Valor em real</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Valor em moeda</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        purchase.map((value,index) => {
  
                                            return( <tr key={'id_'+value.id}>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.id}</p>
                                                        </td>
                                                        <td> 
                                                        <p className="text-sm font-weight-normal mb-0">{value.exchange}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.coin}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.value_brl}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.value_coin}</p>
                                                        </td>
                                                        <td>
                                                            <button style={{marginBottom:'0px'}} onClick={() => { setOpenModal(true); setDialog(value); }} className="btn btn-sm bg-gradient-default ">Editar</button> 
                                                            <button style={{marginBottom:'0px'}} onClick={() => remover(value.id)} className="btn btn-sm bg-gradient-danger ms-2">Remover</button>
                                                        </td>
                                                    </tr> )

                                        })

                                    }
                                </tbody>
                            </table>
                            {

                                (load === false) &&
                                <React.Fragment>
                                    <div style={{display:'flex',justifyContent:'center',margin:'50px 0px'}}><ClipLoader color="#358eed"/></div>
                                </React.Fragment>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal className="modal-dialog-centered" isOpen={openModal} toggle={() => setOpenModal(false)} >
            <form onSubmit={(e) => { e.preventDefault(); if(dialog.id === undefined){ postPurchase(); } if(dialog.id !== undefined){ putPurchase(); } }}>
            <div className="modal-header">
                <h5 className="modal-title"> {(dialog.id === undefined ? 'Cadastrar compra':'Editar compra')} </h5> 
            </div>
            <div className="modal-body"> 
                <div className="row">
                    <div div className="col-md-4 pt-2">
                            <div className="input-group input-group-static">
                                <label>Exchange</label>
                                <input type="text" className="form-control" value={dialog.exchange} onChange={(e) => { setDialog({...dialog,exchange:e.target.value}); }} />
                            </div>
                        </div>
                        <div div className="col-md-4 pt-2">
                            <div className="input-group input-group-static">
                                <label>Moeda</label>
                                <input type="text" className="form-control" value={dialog.coin} onChange={(e) => { setDialog({...dialog,coin:e.target.value}); }} />
                            </div>
                        </div>
                        <div div className="col-md-4 pt-2">
                            <div className="input-group input-group-static">
                                <label>Valor em real</label>
                                <input type="text" className="form-control" value={dialog.value_brl} onChange={(e) => { setDialog({...dialog,value_brl:e.target.value}); }} />
                            </div>
                        </div>
                        <div div className="col-md-12 pt-4">
                            <div className="input-group input-group-static">
                                <label>Valor da moeda</label>
                                <input type="text" className="form-control" value={dialog.value_coin} onChange={(e) => { setDialog({...dialog,value_coin:e.target.value}); }} />
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="modal-footer">
                    <Button type="button" onClick={() => setOpenModal(false)} > Fechar</Button>
                    <Button type="submit" color="primary" >{(dialog.id === undefined ? 'Cadastrar compra':'Salvar alterações')}</Button>
                </div>
            </form>
        </Modal>
    </React.Fragment>)

}