
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {Link} from "react-router-dom";

export default function Cliente(props){

    return (<React.Fragment>
        <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Clientes</h5>
                        </div>
                        <div className="table-responsive">
                            <table className="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                     
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"></th> 
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"><center>VF. Manual</center></th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"><center>VF. E-mail</center></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        props.list.map((value,index) => {
  
                                            return( <tr key={'id_'+value.id}>
                                                     
                                                        
                                                        <td>
                                                            <Link to={'/clients/'+value.id} className="">
                                                                <div className="d-flex px-2 py-1">
                                                                    <div><img src="/img/download.jpg" className="avatar avatar-sm me-3" alt="avatar" /></div>
                                                                    <div className="d-flex flex-column justify-content-center"><h6 className="mb-0 font-weight-normal text-sm">{value.name} {value.last_name}</h6></div>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                     
                                                        
                                                        <td>
                                                            <center>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                            {

                                                                (value.email_verify !== 1) &&
                                                                <React.Fragment><font><i style={{color:"red"}} className="far fa-times-circle"></i></font></React.Fragment>

                                                                }
                                                            {

                                                                (value.email_verify === 1) &&
                                                                <React.Fragment><font><i style={{color:"green"}} className="far fa-check-circle"></i></font></React.Fragment>

                                                            }
                                                            </p>
                                                            </center>
                                                        </td>
                                                        <td>
                                                            <center>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                            {

                                                                (value.manualCheck !== 1) &&
                                                                <React.Fragment><font><i style={{color:"red"}} className="far fa-times-circle"></i></font></React.Fragment>

                                                            }
                                                            {

                                                                (value.manualCheck === 1) &&
                                                                <React.Fragment><font><i style={{color:"green"}} className="far fa-check-circle"></i></font></React.Fragment>

                                                            }
                                                            </p>
                                                            </center>
                                                        </td>
                                                    </tr> )

                                        })

                                    }
                                </tbody>
                            </table>
                            {

                                (props.load === false) &&
                                <React.Fragment>
                                    <div style={{display:'flex',justifyContent:'center',margin:'50px 0px'}}><ClipLoader color="#358eed"/></div>
                                </React.Fragment>

                            }
                            {

                                (props.load === true && props.list.length === 0) &&
                                <div style={{margin:'25px',textAlign:'center'}}>Nenhum cliente foi encontrado.</div>

                            }
                        </div>
                    </div>
                
    </React.Fragment>)

}

                     