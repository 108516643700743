import React, { useEffect, useState } from "react";
import api from "./../Api.js"; 
import ClipLoader from "react-spinners/ClipLoader";
import InputMask from 'react-input-mask';
import moment from "moment";

export default function Login() {
    
    const [form,setForm] = useState('login');
    const [dialog,setDialog] = useState({});
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState(''); 
    const [error,setError] = useState('');
    const [success,setSucess] = useState('');
    const [load,setLoad] = useState(false);
    const [show,setShow] = useState(false);
    const [senha1,setSenha1] = useState('');
    const [senha2,setSenha2] = useState('');
    
    const login = (() => {

        setLoad(true);
        api.auth({email:email,password:password}).then(async (res) => { 

            window.user = res.data.client;
            window.cookies.set("session",res.data.session,{path:"/"});
            window.update();

        }).catch((error) => {

            setError(error.response.data.msg);
            setLoad(false);

        });
 
    });


    const recoverpassword = (() => {

        setLoad(true);
        api.recoverpassword(email).then(async (res) => { 

            setSucess('O link de redefinição de senha foi enviado para o seu')
            setLoad(false);

        }).catch((error) => {

            setError(error.response.data.msg);
            setLoad(false);

        });
 
    });

    window.login = login;
 
  
    const register = (() => {

        var obj = JSON.parse(JSON.stringify(dialog));
        obj.birth_date = moment(obj.birth_date,'DD/MM/YYYY').format('YYYY-MM-DD');

        obj.email_verify = parseInt(obj.email_verify);
        obj.admin = parseInt(obj.admin);
        obj.block = parseInt(obj.block);

        console.log(obj);

         
        if(dialog.password !== dialog.password2){

            const Toast = window.swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:'A senha é diferente da confirmação da senha.'});
            return false;

        }
        
        api.register(obj).then(async (res) => { 
 
            setEmail(obj.email);
            setPassword(obj.password);
            setForm('login');
            setTimeout(() => { window.login(); }, 100);

            /*const Toast = window.swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'success',title:'O cliente foi cadastrado.'});*/
            
        }).catch((error) => {

            const Toast = window.swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:error.response.data.msg});

        });
 

    });

    const verify = (() => {

        api.verify().then(async (res) => { 

            window.user = res.data;
            window.update();
            setShow(true);

        }).catch((error) => {

            setShow(true);

        });

    });


    const recoverpassword2 = (() => {

        var obj = {code:window.parseQueryString().recovercode,password:senha1,confirmpassword:senha2};

        api.resetpassword(obj).then(async (res) => { 

            window.user = res.data.client;
            window.cookies.set("session",res.data.session,{path:"/"});
            window.update();

        }).catch((error) => {

            setShow(true);

            setError(error.response.data.msg);

        });

    });
    
    useEffect(() => {

        verify();

        if(window.parseQueryString().recovercode !== undefined){

            setForm('recover2');

        }

        return (() => {});
        
    },[]);

    if(show === false){ return(<React.Fragment></React.Fragment>) }

    return (
    <div className="App"> 

        <style>{'.bg-gradient-info { background-image: linear-gradient(195deg,#49a3f1,#0c0019); }'}</style>

        <iframe src="/anim/index.html" style={{border:'none',position:'fixed',width:'100%',height:'100%'}} title="..." />

        <div className="page-header align-items-start min-vh-100" style={{}} >
            
            <div className="container my-auto">
                <div className="row">
                    <div className="col-lg-4 col-md-8 col-12 mx-auto" style={{marginTop:'100px',marginBottom:'100px'}}>
                        
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'relative',top:'-40px'}}>
                            <img src="/img/logos/logo_armata_1.png" alt="..." style={{height:'45px'}}/>
                        </div>
                        

                        <div className="card z-index-0 fadeIn3 fadeInBottom">
                          
                            <div className="card-body">
                                {

                                    (error !== "") &&
                                    <div className="animate__animated animate__fadeIn alert alert-danger alert-dismissible text-white" role="alert">
                                        <span className="text-sm"><strong>Ops...</strong> {error}</span>
                                        <button onClick={() => { setError(''); }} type="button" className="btn-close text-lg py-3 opacity-10" data-bs-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                        </button>
                                    </div>

                                }
                                {

                                    (success !== "") &&
                                    <div className="animate__animated animate__fadeIn alert alert-success alert-dismissible text-white" role="alert">
                                        <span className="text-sm"><strong>Sucesso! </strong> {success}</span>
                                        <button onClick={() => { setSucess(''); }} type="button" className="btn-close text-lg py-3 opacity-10" data-bs-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                        </button>
                                    </div>

                                }
                                {

                                    (form === "login") &&
                                    <React.Fragment>
                                        <form onSubmit={(e) => { e.preventDefault(); login(); }}>
                                            <div className="input-group input-group-outline my-3"> 
                                                <input placeholder="E-mail" type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                            </div>
                                            <div className="input-group input-group-outline mb-3"> 
                                                <input placeholder="Senha" type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}/>
                                            </div>
                                           
                                            <div className="text-center">
                                                <button type="submit" className="btn bg-gradient-info w-100 mb-2">{load === true ? <ClipLoader color="#fff" size={16} />:'Entrar'}</button>
                                            </div>
                                            <p className="mt-4 text-sm text-center">
                                                Não tem uma conta?
                                                <font onClick={() => { setForm('register'); setError(''); setSucess(''); }} style={{cursor:'pointer'}} className="text-primary text-gradient font-weight-bold"> Cadastre-se</font>
                                            </p>
                                            <p className="mt-4 text-sm text-center">
                                                Esqueceu a senha?
                                                <font onClick={() => { setForm('recover');  setError(''); setSucess(''); }} style={{cursor:'pointer'}} className="text-primary text-gradient font-weight-bold"> Recuperar</font>
                                            </p>
                                        </form>
                                    </React.Fragment>

                                }


                                {

                                    (form === "recover") &&
                                    <React.Fragment>
                                        <form onSubmit={(e) => { e.preventDefault(); recoverpassword(); }}>
                                            <div className="input-group input-group-outline my-3"> 
                                                <input placeholder="E-mail" type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn bg-gradient-info w-100 my-4 mb-2">{load === true ? <ClipLoader color="#fff" size={16} />:'Recuperar senha'}</button>
                                            </div>
                                            <p className="mt-4 text-sm text-center">
                                                Opa lembrou a senha?
                                                <font onClick={() => { setForm('login'); }} style={{cursor:'pointer'}} className="text-primary text-gradient font-weight-bold"> Logar</font>
                                            </p>
                                        </form>
                                    </React.Fragment>
                                    
                                }

                                {

                                    (form === "recover2") &&
                                    <React.Fragment>
                                        <form onSubmit={(e) => { e.preventDefault(); recoverpassword2(); }}>
                                            <div className="input-group input-group-outline my-3"> 
                                                <input type="password" placeholder="Nova senha" className="form-control" value={senha1} onChange={(e) => setSenha1(e.target.value)}/>
                                            </div>
                                            <div className="input-group input-group-outline my-3"> 
                                                <input type="password" placeholder="Confirme a nova senha" className="form-control" value={senha2} onChange={(e) => setSenha2(e.target.value)}/>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn bg-gradient-info w-100 my-4 mb-2">{load === true ? <ClipLoader color="#fff" size={16} />:'Redefinir senha'}</button>
                                            </div> 
                                        </form>
                                    </React.Fragment>

                                }

                                {

                                    (form === "register") &&
                                    <form onSubmit={(e) => { e.preventDefault(); register(); }}>
                                        <h6>Pessoal</h6>
                                        <div className="col-md-12 pt-2">
                                            <div className="input-group input-group-static">
                                                <label>Nome</label>
                                                <input type="text" className="form-control" value={dialog.name} onChange={(e) => { setDialog({...dialog,name:e.target.value}); }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-2">
                                            <div className="input-group input-group-static">
                                                <label>Sobrenome</label>
                                                <input type="text" className="form-control" value={dialog.last_name} onChange={(e) => { setDialog({...dialog,last_name:e.target.value}); }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-2">
                                            <div className="input-group input-group-static">
                                                <label>CPF (opcional)</label>
                                                <InputMask value={dialog.cpf} onChange={(e) => { setDialog({...dialog,cpf:e.target.value}); }} className="form-control" mask="999.999.999-99" maskChar=" "  />
                                            </div>
                                        </div>
                                        {/* 
                                        <div className="col-md-12 pt-2">
                                            <div className="input-group input-group-static">
                                                <label>Data de nascimento</label>
                                                <InputMask value={dialog.birth_date} onChange={(e) => { setDialog({...dialog,birth_date:e.target.value}); }} className="form-control" mask="99/99/9999" maskChar=" "  />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-2">
                                            <div className="input-group input-group-static">
                                                <label>CPF</label>
                                                <InputMask value={dialog.cpf} onChange={(e) => { setDialog({...dialog,cpf:e.target.value}); }} className="form-control" mask="999.999.999-99" maskChar=" "  />
                                            </div>
                                        </div>
                                        <h6 className="pt-4">Endereço</h6>
                                        <div className="col-md-12 pt-2">
                                            <div className="input-group input-group-static">
                                                <label>CEP</label>
                                                <InputMask value={dialog.zip_code} onChange={(e) => { if(e.target.value.length === 9){ cep(e.target.value); } setDialog({...dialog,zip_code:e.target.value}); }} className="form-control" mask="99999-999" maskChar=" "  />
                                            </div>
                                        </div>

                                        <div className="col-md-12 pt-2">
                                            <div className="input-group input-group-static">
                                                <label>Rua</label>
                                                <input type="text" className="form-control" value={dialog.street} onChange={(e) => { setDialog({...dialog,street:e.target.value}); }} />
                                            </div>
                                        </div>
                                        */}

                                        {/* <div className="col-md-12 pt-4">
                                            <div className="input-group input-group-static">
                                                <label>Estado</label>
                                                <input type="text" className="form-control" value={dialog.state} onChange={(e) => { setDialog({...dialog,state:e.target.value}); }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-4">
                                            <div className="input-group input-group-static">
                                                <label>Cidade</label>
                                                <input type="text" className="form-control" value={dialog.city} onChange={(e) => { setDialog({...dialog,city:e.target.value}); }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-4">
                                            <div className="input-group input-group-static">
                                                <label>Número</label>
                                                <input type="text" className="form-control" value={dialog.number} onChange={(e) => { setDialog({...dialog,number:e.target.value}); }} />
                                            </div>
                                        </div>*/}

                                        <h6 className="pt-4">Contato</h6>
                                        <div className="col-md-12 pt-4">
                                            <div className="input-group input-group-static">
                                                <label>E-mail</label>
                                                <input type="text" className="form-control" value={dialog.email} onChange={(e) => { setDialog({...dialog,email:e.target.value}); }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-4">
                                            <div className="input-group input-group-static">
                                                <label>Telefone/celular</label>
                                                <InputMask value={dialog.phone} onChange={(e) => { setDialog({...dialog,phone:e.target.value}); }} className="form-control" mask="(99) 9999-9999999" maskChar=" "  />
                                            </div>
                                        </div>

                                        <h6 className="pt-4">Senha de acesso</h6>
                                        <div className="col-md-12 pt-4">
                                            <div className="input-group input-group-static">
                                                <label>Senha</label>
                                                <input type="password" className="form-control" value={dialog.password} onChange={(e) => { setDialog({...dialog,password:e.target.value}); }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-4">
                                            <div className="input-group input-group-static">
                                                <label>Confirme a senha</label>
                                                <input type="password" className="form-control" value={dialog.password2} onChange={(e) => { setDialog({...dialog,password2:e.target.value}); }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit" className="btn bg-gradient-dark btn-sm mt-4 mb-0" style={{width:'100%'}}>Realizar cadastro</button>
                                        </div>
                                        <div className="col-md-12" >
                                            <p className="mt-4 text-sm text-center">
                                                Ja tem uma conta?
                                                <font onClick={() => { setForm('login'); }} style={{cursor:'pointer'}} className="text-primary text-gradient font-weight-bold"> Logar</font>
                                            </p>
                                        </div>
                                         
                                    </form>

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );


}