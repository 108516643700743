
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {Link} from "react-router-dom";

export default function Pedidos(props){

    const returnSigla = ((coin) => {

        var cds = props.coins.find(x => x.coin === coin);
        if(cds !== undefined){

            return cds.coinmin;

        }

        return '';
        

    });

    

    return (<React.Fragment>
        <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Pedidos</h5>
                        </div>
                        <div className="table-responsive">
                            <table className="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"></th>  
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Cliente</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Tipo</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Reais</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Moedas</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Cotação</th> 
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Status</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Lucro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        props.list.map((value,index) => {
  
                                            var coin = props.coins.find(x => x.coin === value.coin);
                                            if(coin === undefined){ coin = {}; }

                                            return( <tr key={'id_'+value.id}>
                                                     
                                                        <td>
                                                            <div className="d-flex px-2 py-1 align-items-center">
                                                                <img src={coin.icon} className="avatar avatar-sm me-3 border-radius-lg" alt="user1" />
                                                                <h6 className="text-sm font-weight-normal mb-0 ">{coin.name}</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                        <Link to={"/clients/"+value.dataClient.id}>{value.dataClient.name} {value.dataClient.last_name}</Link>
                                                        </td>
                                                        <td>
                                                            {
                                                                
                                                                (value.type === "buy") &&
                                                                <React.Fragment><strong style={{color:'green'}}>Compra</strong></React.Fragment>

                                                            }
                                                            {
                                                                
                                                                (value.type === "sell") &&
                                                                <React.Fragment><strong style={{color:'red'}}>Venda</strong></React.Fragment>

                                                            }
                                                        </td>
                                                        <td><React.Fragment>R$ {value.brl}</React.Fragment></td>
                                                        <td><React.Fragment>{value.coin_amount} {returnSigla(coin.coin)}</React.Fragment></td>
                                                        <td>

                                                            {

                                                                (value.currentQuote !== null) &&
                                                                <React.Fragment>R$ {value.currentQuote.toFixed(2)}</React.Fragment>

                                                            }

                                                        </td>
                                                        <td>
                                                            {

                                                                (value.status === "pending") &&
                                                                <React.Fragment><strong style={{color:"red"}}>Pendente</strong></React.Fragment>

                                                            }
                                                            {

                                                                (value.status === "concluded") &&
                                                                <React.Fragment><strong style={{color:"green"}}>Concluído</strong></React.Fragment>

                                                            }
                                                            {

                                                                (value.status === "paid") &&
                                                                <React.Fragment><strong style={{color:"paid"}}>Pago</strong></React.Fragment>

                                                            }
                                                            {

                                                                (value.status === "canceled") &&
                                                                <React.Fragment><strong style={{color:"black"}}>Cancelado</strong></React.Fragment>

                                                            }
                                                        </td>
                                                        <td>
                                                        {
                                                            
                                                            (value.profit !== null) &&
                                                            <React.Fragment>R$ {value.profit.toFixed(2)}</React.Fragment>

                                                        }
                                                        </td>
                                                    </tr> )

                                        })

                                    }
                                </tbody>
                            </table>
                            {

                                (props.load === false) &&
                                <React.Fragment>
                                    <div style={{display:'flex',justifyContent:'center',margin:'50px 0px'}}><ClipLoader color="#358eed"/></div>
                                </React.Fragment>

                            }
                            {

                                (props.load === true && props.list.length === 0) &&
                                <div style={{margin:'25px',textAlign:'center'}}>Nenhum pedido foi encontrado.</div>

                            }
                        </div>
                    </div>
                
    </React.Fragment>)

}

                     