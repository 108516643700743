import React,{useState,useEffect} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import api from "./../../Api.js";
import {Button,Modal} from "reactstrap";
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import moment from "moment";

window.formatter = new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL',});

const bancos = require('./../../banco_codigo.json');

export default function Home(){
    
    const [compraFeita,setcompraFeita] = useState({});

    const [load,setLoad] = useState(false);
    const [coins,setCoins] = useState([]);
    const [scompra,setScompra] = useState(); 

    //const [coinValues,setCoinsValues] = useState([]);
    const [dialog,setDialog] = useState({openModal:false});
    let navigate = useNavigate();

    /*const getCoins = (async () => {

        api.getCoins().then(async (res) => { 

            window.coins = res.data;
            for(let index = 0; index < window.coins.length; index++){ getCoinsValue(window.coins[index].coin); }

            setCoins(res.data);
            setLoad(true);

        }).catch((error) => {});

    });*/


    const getCoinsValue = (async (coin) => {

        await api.getCoinsValue(coin).then(async (res) => { 

            console.log('resposta recebida');

            var coinVal = window.coins.find(x => x.coin === coin);
            
            if(coinVal !== undefined){

                coinVal.value = res.data.value;

            } 
 
            window.setCoins(JSON.parse(JSON.stringify(window.coins)));

        }).catch((error) => {});

    });

    const manualCheck = (() => {

        window.swal.fire('Verificação de conta','Em breve entraremos em contato com o número de celular informado. <br/> <br/> Certifique que seu número está correto: <br/><br/><strong>'+window.user.phone+'</strong>','info');

    });

    const cancelOrder = ((id) => {

        window.swal.fire({
            position:"center",
            toast: true,
            title: 'Você deseja cancelar a transação?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar"
        }).then((result) => {
      
            if(result.value){
      
                api.cancelTransaction(id).then((res) => {
      
                  const Toast = window.swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', window.swal.stopTimer)
                      toast.addEventListener('mouseleave', window.swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({icon:'success',title:'A transação foi cancelada.'});
                  setDialog({openModal:false});
                  
                }).catch(error => {
      
                    if(error !== undefined){
      
                        if(error.response !== undefined){
      
                            if (error.response.data !== undefined){
      
                                if(error.response.data.msg !== undefined){
                                
                                    const Toast = window.swal.mixin({
                                        toast: true,
                                        position: 'top',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', window.swal.stopTimer)
                                            toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                        }
                                    })
                                    
                                    Toast.fire({icon:'error',title:error.response.data.msg});
                                  
                                }
      
                            }
      
                        }
      
                    }
      
                });
      
            }
      
        });
      
    });

    const req = (async () => {

        
        setDialog({...dialog,loading:true});
        
        var obj = {};
        obj.coin = dialog.coin;
        obj.type = dialog.type;
        obj.wallet = dialog.wallet;
        
        if(obj.type === "buy"){

            obj.brl = dialog.ammount;

        }

        if(obj.type === "sell"){

            obj.coin_amount = dialog.ammount;
            obj.bank = dialog.bank;
            obj.ag = dialog.ag;
            obj.cc = dialog.cc;

        }

        await api.userPostTransation(obj).then(async (res) => { 
            
            setScompra(moment().format('DD/MM/YYYY')+' às '+moment().format('HH:MM:SS'))
            setDialog({...dialog,status:"etapa_2",loading:false});
            setcompraFeita(res.data);

        }).catch((error) => {

            setDialog({...dialog,loading:false});

            if(error !== undefined){
      
                if(error.response !== undefined){

                    if (error.response.data !== undefined){

                        if(error.response.data.msg !== undefined){
                        
                            const Toast = window.swal.mixin({
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                }
                            })
                            
                            Toast.fire({icon:'error',title:error.response.data.msg});

                        }

                    }

                }

            }

        });


    })


    useEffect(() => {

        api.getCoins().then(async (res) => { 

            window.coins = res.data;
            for(let index = 0; index < window.coins.length; index++){ getCoinsValue(window.coins[index].coin); }

            setCoins(res.data);
            setLoad(true);

        }).catch((error) => {});
         
        return (() => { });

    },[]);

    useEffect(() => {

        for(let index = 0; index < window.coins.length; index++){ getCoinsValue(window.coins[index].coin); }
        var getCoinsValues = setInterval(() => {

            for(let index = 0; index < window.coins.length; index++){ getCoinsValue(window.coins[index].coin); }
            
        },10*1000);

        return (() => { clearInterval(getCoinsValues); });

    },[]);


    window.coins = coins;
    window.setCoins = setCoins;

    console.log('deu render');

    setTimeout(() => {

        var inp = document.getElementById('amount');
        window.inp = inp;

    }, 200);


    window.ccoin = undefined;
    if(dialog.openModal === true){

        window.ccoin = window.coins.find(x => x.coin === dialog.coin);

    }
    
    return (
    <React.Fragment>
        <div className="container-fluid py-4">
            <div className=" ">
                <div className="col-lg-12 position-relative z-index-2">
                    <div className="card mb-4 ">
                        <div className="d-flex">
                            <div className="icon icon-shape icon-lg bg-gradient-success shadow text-center border-radius-xl mt-n3 ms-4">
                                <i className="material-icons opacity-10" aria-hidden="true"><i className="fab fa-bitcoin"></i></i>
                            </div>
                            <h6 className="mt-3 mb-2 ms-3 ">Moedas disponíveis</h6>
                        </div>
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="table-responsive">
                                        <table className="table align-items-center ">
                                            <tbody>
                                                {

                                                    coins.map((value,index) => {
 

                                                        return (
                                                            <tr key={'id_'+value.id}>
                                                                <td className="w-30">
                                                                        <div className="d-flex px-2 py-1 align-items-center">
                                                                        <img src={value.icon} className="avatar avatar-sm me-3 border-radius-lg" alt="user1" />
                                                                        <div>
                                                                    </div>
                                                                    <div className="ms-4">
                                                                        <p className="text-xs font-weight-bold mb-0 ">Moeda:</p>
                                                                        <h6 className="text-sm font-weight-normal mb-0 ">{value.name}</h6>
                                                                    </div>
                                                                    </div>
                                                                </td>
                                                                
                                                                {

                                                                
                                                                <td className="td_v_compra">
                                                                    <div className="text-center">
                                                                    <p className="text-xs font-weight-bold mb-0 ">Valor de compra:</p>
                                                                    {

                                                                        (value.value === undefined) &&
                                                                        <React.Fragment><div><ClipLoader color="#4099ef" size="15px" /></div></React.Fragment>

                                                                    }

                                                                    {

                                                                        (value.value !== undefined) &&
                                                                        <React.Fragment>
                                                                            <h6 className="text-sm font-weight-normal mb-0 " tax_buy={value.tax_buy}>{ window.formatter.format( (value.value /*- (value.value * (value.tax_buy / 100) )*/) )}</h6>
                                                                            {/*value.tax_buy*/}
                                                                        </React.Fragment>

                                                                    }
                                                                     
                                                                    </div>
                                                                </td>
                                                                
                                                                }


                                                                <td className="td_v_venda">
                                                                    <div className="text-center">
                                                                    <p className="text-xs font-weight-bold mb-0 ">Valor de venda:</p>
                                                                    {

                                                                        (value.value === undefined) &&
                                                                        <React.Fragment><div><ClipLoader color="#4099ef" size="15px" /></div></React.Fragment>

                                                                    }

                                                                    
                                                                    {

                                                                        (value.value !== undefined) &&
                                                                        <React.Fragment>
                                                                            <h6 className="text-sm font-weight-normal mb-0 " tax_sell={value.tax_sell}>{window.formatter.format(value.value - (value.value * (value.tax_sell / 100) )  )}</h6>
                            
                                                                        </React.Fragment>

                                                                    }
                                                                    </div>
                                                                </td>

                                                                {

                                                                    (value.value !== undefined) &&
                                                                    <td className="align-middle text-sm">
                                                                        {

                                                                            (value.sell === 1) &&
                                                                            <button onClick={() => { if(window.user.manualCheck !== 1){ manualCheck(); return false; } setcompraFeita({}); setDialog({status:"etapa_1",openModal:true,type:"buy",coin:value.coin,ammount:(/*value.value - (value.value * (value.tax_sell / 100) )*/3000).toFixed(2)   , icon:value.icon,name:value.name  }); }} className="btn btn-sm bg-gradient-success ms-2" style={{marginBottom:'0px'}}>Comprar</button>

                                                                        }
                                                                        {

                                                                            (value.buy === 1) &&
                                                                            <button onClick={() => { if(window.user.manualCheck !== 1){ manualCheck(); return false; } setcompraFeita({}); setDialog({status:"etapa_1",openModal:true,type:"sell",coin:value.coin,ammount:0   , icon:value.icon,name:value.name  }); }} className="btn btn-sm bg-gradient-danger ms-2" style={{marginBottom:'0px'}}>Vender</button>

                                                                        }
                                                                    </td>

                                                                }
                                                                
                                                            </tr>)

                                                    })

                                                }
                                            </tbody>
                                        </table>
                                        {

                                            (coins.length === 0 && load === true) &&
                                            <React.Fragment><div style={{textAlign:'center',color:'#6a6a6a'}}>Não opções disponíveis.</div></React.Fragment>

                                        }
                                        {

                                            (load === false) &&
                                            <React.Fragment><div style={{display:'flex',justifyContent:'center',marginTop:'30px',height:'65px'}}><ClipLoader color="#358fee" size={40} /></div></React.Fragment>

                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-5">
                                </div>
                            </div>
                        </div>
                    </div>  
                </div> 
            </div> 
        </div>









        <Modal className="modal-dialog-centered" isOpen={dialog.openModal} toggle={() => setDialog({openModal:false})} >
            <form onSubmit={(e) => { e.preventDefault(); req(); }}>
            <div hidden className="modal-header">
                <h2 className="modal-title"> {(dialog.id === undefined ? 'Comprar moeda':'Editar moeda')} </h2> 
            </div>
            <div className="modal-body">
                {

                    (dialog.status === 'etapa_1') &&
                    <div style={{textAlign:'center'}}>
                        <h2 className="modal-title" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={dialog.icon} style={{height:'50px'}} alt="..."/>
                            <font style={{marginLeft:'15px'}}>{dialog.name}</font>
                        </h2>
                    </div>

                }
                
                <div className="row" style={{marginTop:'10px'}}> 
                        {

                            (dialog.type === "buy" && dialog.status === 'etapa_1') &&
                            <React.Fragment>

                                    
                                <div hidden style={{textAlign:'center'}} >Cotação de 1 {window.ccoin.coinmin}: <strong> {window.formatter.format( (window.ccoin.value + (window.ccoin.value * ( window.ccoin.tax_sell / 100))).toFixed(2))  }</strong></div>
                                <div  style={{textAlign:'center'}} >Cotação de 1 {window.ccoin.coinmin}: <strong> {window.formatter.format( (window.ccoin.value).toFixed(2))  }</strong></div>


                                <div hidden style={{textAlign:'center'}} >Valor na CoinGecko: <strong>R$ {window.ccoin.value.toFixed(2)}</strong></div>
                                <div hidden style={{textAlign:'center'}} >Taxa de compra: <strong>{window.ccoin.tax_sell}%</strong></div>
                                <div hidden style={{textAlign:'center'}} >Valor na Armata: <strong>R$ {(window.ccoin.value + (window.ccoin.value * ( window.ccoin.tax_sell / 100))).toFixed(2)  }</strong></div>
                                <div hidden style={{textAlign:'center'}}>Comprar <strong>{(parseFloat(dialog.ammount) / (window.ccoin.value + (window.ccoin.value * ( window.ccoin.tax_sell / 100))) ).toFixed(6)  }</strong> {dialog.coin} por <strong>R$ {dialog.ammount}</strong></div> 
                                
                                <div style={{textAlign:'center',marginTop:'10px'}}>Quanto em <strong>R$</strong> deseja comprar?</div>

                                <div className="input-group input-group-outline mb-3" style={{display:'flex',justifyContent:'center',marginTop:'5px'}}>
                                    <button type="button" disabled={dialog.loading} onClick={() => {var am = (parseFloat(dialog.ammount) +500 ).toFixed(2);   if(parseFloat(am) <= 0){ am = 0; } setDialog({...dialog,ammount:am}); window.inp.value = am;  }} className="btn btn-info" style={{marginBottom:'0px'}}><i className="fas fa-plus"></i></button> 
                                    <input  disabled={dialog.loading} id="amount" style={{width:'220px',flex:'inherit',textAlign:'center',border:'none',fontWeight:'600',fontSize:'20px'}}  type="text" className="form-control" onChange={(e) => {     e.target.value = e.target.value.replace(',','.');   if(isNaN(parseFloat(e.target.value)) === true){ return false; }  setDialog({...dialog,ammount:parseFloat(e.target.value).toFixed(2)}) }} defaultValue={dialog.ammount} />
                                    <button type="button" disabled={dialog.loading} onClick={() => { var am = (parseFloat(dialog.ammount) -500 ).toFixed(2);   if(parseFloat(am) <= 0){ am = 0; } setDialog({...dialog,ammount:am}); window.inp.value = am;  }} className="btn btn-info" style={{marginBottom:'0px'}}><i className="fas fa-minus"></i></button> 
                                </div>

                                <div style={{textAlign:'center'}} >Comprar <strong>{(parseFloat(dialog.ammount) / (window.ccoin.value + (window.ccoin.value * ( window.ccoin.tax_sell / 100))) ).toFixed(6)  } {window.ccoin.coinmin} </strong> por <strong>{window.formatter.format(dialog.ammount)}</strong></div>
                                <div style={{textAlign:'center',fontWeight:'100',fontSize:'13px'}}>(Taxas já inclusas!)</div>
                                
                                <div style={{textAlign:'center',marginTop:'10px'}}>Informe o endereço para envio:</div>
                                <div className="input-group input-group-outline mb-3" style={{display:'flex',justifyContent:'center',marginTop:'5px'}}>
                                    <input disabled={dialog.loading} id="amount" style={{width:'300px',flex:'inherit',textAlign:'center',fontWeight:'100',fontSize:'15px'}}  type="text" className="form-control" onChange={(e) => { setDialog({...dialog,wallet:e.target.value}); }} value={dialog.wallet} />
                                </div>

                                <div>
                                    <div style={{marginTop:'20px',textAlign:'center',padding:'8px'}} className="alert alert-warning alert-dismissible text-white" role="alert">
                                        <span className="text-sm" style={{textAlign:'center'}}><strong>Atenção!</strong> Certifique-se que o endereço esteja correto.</span>
                                    </div>
                                </div>

                            </React.Fragment>

                        }
                        {
 
                            (dialog.type === "sell" && dialog.status === 'etapa_1') &&
                            <React.Fragment>
                                <div style={{textAlign:'center'}}>Vender <strong>{parseFloat(dialog.ammount).toFixed(6)  }</strong> {dialog.coin} por <strong>R$ {(dialog.ammount * window.coins.find(x => x.coin === dialog.coin).value).toFixed(2)}</strong></div> 
                            
                                <div style={{textAlign:'center',marginTop:'10px'}}>Quantas moedas deseja vender?</div>
                                <div className="input-group input-group-outline mb-3" style={{display:'flex',justifyContent:'center',marginTop:'0px'}}>
                                    <button type="button" disabled={dialog.loading} onClick={() => {var am = (parseFloat(dialog.ammount) +0.000100 ).toFixed(6);    setDialog({...dialog,ammount:am}); window.inp.value = am;   }} className="btn btn-info" style={{marginBottom:'0px'}}><i className="fas fa-plus"></i></button> 
                                    <input  disabled={dialog.loading} id="amount" style={{width:'220px',flex:'inherit',textAlign:'center',border:'none',fontWeight:'600',fontSize:'20px'}}  type="text" className="form-control" onChange={(e) => {     e.target.value = e.target.value.replace(',','.');    if(isNaN(parseFloat(e.target.value)) === true){ return false; }  setDialog({...dialog,ammount:parseFloat(e.target.value)}) }} defaultValue={dialog.ammount} />
                                    <button type="button" disabled={dialog.loading} onClick={() => {var am = (parseFloat(dialog.ammount) -0.000100 ).toFixed(6);    setDialog({...dialog,ammount:am}); window.inp.value = am;   }} className="btn btn-info" style={{marginBottom:'0px'}}><i className="fas fa-minus"></i></button> 
                                </div>

                                <div style={{textAlign:'center',marginTop:'10px'}}>De qual carteira as moedas serão enviada?</div>
                                <div className="input-group input-group-outline mb-3" style={{display:'flex',justifyContent:'center',marginTop:'5px'}}>
                                    <input disabled={dialog.loading} style={{width:'300px',flex:'inherit',textAlign:'center',fontWeight:'100',fontSize:'15px'}}  type="text" className="form-control" onChange={(e) => { setDialog({...dialog,wallet:e.target.value}); }} value={dialog.wallet} />
                                </div>

                                <style>{' .sls > div{ width: 300px; } '}</style>

                                <div style={{textAlign:'center',marginTop:'10px'}}>Para qual banco faremos a transferência?</div>
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    <div className="input-group  mb-3 sls" style={{display:'flex',justifyContent:'center',marginTop:'5px',width:'300px'}}>
                                        <Select getStyles={'teste'} options={bancos} disabled={dialog.loading} style={{width:'300px',flex:'inherit',textAlign:'center',fontWeight:'100',fontSize:'15px'}}  type="text" className="form-control" onChange={(value) => { setDialog({...dialog,bank:value.value}); }} /*value={dialog.wallet}*/ />
                                    </div>
                                </div>

                                <div style={{textAlign:'center',marginTop:'10px'}}>Agência:</div>
                                <div className="input-group input-group-outline mb-3" style={{display:'flex',justifyContent:'center',marginTop:'5px'}}>
                                    <input disabled={dialog.loading} style={{width:'300px',flex:'inherit',textAlign:'center',fontWeight:'100',fontSize:'15px'}}  type="text" className="form-control" onChange={(e) => { setDialog({...dialog,ag:e.target.value}); }} value={dialog.ag} />
                                </div>

                                <div style={{textAlign:'center',marginTop:'10px'}}>Conta:</div>
                                <div className="input-group input-group-outline mb-3" style={{display:'flex',justifyContent:'center',marginTop:'5px'}}>
                                    <input disabled={dialog.loading} style={{width:'300px',flex:'inherit',textAlign:'center',fontWeight:'100',fontSize:'15px'}}  type="text" className="form-control" onChange={(e) => { setDialog({...dialog,cc:e.target.value}); }} value={dialog.cc} />
                                </div>

                            </React.Fragment>

                        }
                        {

                            (dialog.type === "buy" && dialog.status === 'etapa_2' /* 'etapa_2' */) &&
                            <React.Fragment>
                                <div>
                                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><i className="far fa-check-circle" style={{fontSize:'30px',marginRight:'9px'}}></i><h4 style={{marginBottom:'0px'}}>Pedido enviado</h4></div>
                                    <div style={{textAlign:'center'}}>em {scompra} (GMT -3)</div>
                                    <div>
                                        <div style={{textAlign:'center'}}><p><br/>Para concluir o pedido basta fazer o pagamento para a chave PIX:</p></div>
                                        <div>
                                            <div style={{marginTop:'20px',textAlign:'center',padding:'8px'}} className="alert alert-success alert-dismissible text-white" role="alert">
                                                <span className="text-sm" style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html:window.configuration.pix}} ></span>
                                            </div>
                                        </div>
                                        {

                                            (compraFeita.pix_qrcode_image !== null) &&
                                            <React.Fragment>
                                                <center>QR code para pix</center>
                                                <center><img src={compraFeita.pix_qrcode_image} alt="..." /></center>
                                            </React.Fragment>

                                        }
                                        <div style={{textAlign:'center'}}><p>Valor do pagamento: <br/><strong style={{color:'green'}}>{window.formatter.format(dialog.ammount)}</strong> </p></div>
                                        <div style={{textAlign:'center'}}> <strong style={{color:'#c9c94a'}}>{(parseFloat(dialog.ammount) / (window.ccoin.value + (window.ccoin.value * ( window.ccoin.tax_sell / 100))) ).toFixed(6)  } {window.ccoin.coinmin} </strong> a receber.</div>
                                        <div>
                                            <div style={{marginTop:'20px',textAlign:'center',padding:'8px'}} className="alert alert-secondary alert-dismissible text-white" role="alert">
                                                <span className="text-sm" style={{textAlign:'center'}}><strong>Atenção!</strong> Pedido válido por apenas 15 minutos!</span>
                                            </div>
                                        </div>
                                        <br/>
                                        <div style={{textAlign:'center'}}><p>Qualquer dúvida entre em contato com o whatsapp: <br/><strong>{window.configuration.telefone}</strong> </p></div>
                                    </div>
                                </div>
                            </React.Fragment>

                        }
                        {

                            (dialog.type === "sell" && dialog.status === "etapa_2") &&
                            <React.Fragment>
                                <div>

                                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><i className="far fa-check-circle" style={{fontSize:'30px',marginRight:'9px'}}></i><h4 style={{marginBottom:'0px'}}>Solicitação enviada</h4></div>
                                    <div>
                                        
                                        <div style={{textAlign:'center'}}><p><br/>Para vender as moedas basta depositalas na carteira da armata.</p></div>
                                        
                                        <div style={{textAlign:'center'}}>
                                            <p>
                                                Cateira de depósito:<br/><strong>{window.coins.find(x => x.coin === dialog.coin).wallet}</strong><br/>
                                            </p>
                                        </div>

                                        <div style={{textAlign:'center'}}><p>Valor solicitado: <br/><strong style={{color:'green'}}>{dialog.ammount} {dialog.coin}</strong> </p></div>
                                        <div style={{textAlign:'center'}}><p>Qualquer dúvida entre em contato com o whatsapp: <br/><strong>(15) 93300122</strong> </p></div>
 

                                    </div>

                                </div>
                            </React.Fragment>

                        }
                    </div> 
                </div>

                {

                    (dialog.status === 'etapa_1'  && dialog.loading !== true) &&
                    <div className="modal-footer" style={{display:'flex',justifyContent:'center'}}>
                        <Button type="button" onClick={() => setDialog({openModal:false})} > Fechar</Button>
                        <Button type="submit" color="primary"> <i className="fas fa-file-invoice-dollar"></i> Realizar pedido</Button>
                    </div>

                }

 

                {

                    (dialog.status === 'etapa_2' && dialog.loading !== true) &&
                    <div className="modal-footer" style={{display:'flex',justifyContent:'center'}}>
                        <Button type="button" onClick={() => setDialog({openModal:false})} > Fechar</Button>
                        <Button onClick={() => { cancelOrder(compraFeita.id);  }} type="button" color="primary"> <i class="fa fa-ban"></i> Cancelar pedido</Button>
                        <Button onClick={() => { navigate('/transations'); }} type="button" color="success"> <i className="fas fa-file-invoice-dollar"></i> Históricos</Button>
                    </div>

                }
                 
                {

                    (dialog.status === "etapa_1" && dialog.loading === true) &&
                    <React.Fragment>
                        <div className="modal-footer" style={{display:'flex',justifyContent:'center'}}>
                            <ClipLoader color="#4099ef" size="40px" />
                        </div>
                    </React.Fragment>

                }


            </form>
        </Modal>
    </React.Fragment>)

}