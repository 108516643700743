import React,{useState,useEffect} from "react";
import api from "./../../Api.js";
import ClipLoader from "react-spinners/ClipLoader";
import {Link} from "react-router-dom";
import moment from "moment";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export default function Clients(){

    const [load,setLoad] = useState(false);
    const [clientList,setClientsList] = useState([]);
 
    const getClients = (async () => {

        setLoad(false);
        api.getClients().then(async (res) => { 

            setLoad(true);
            setClientsList(res.data);

        }).catch((error) => {

            setLoad(false);

        });

    });

 

    const remover = ((id) => {

        window.swal.fire({
            position:"center",
            toast: true,
            title: 'Você deseja remover cliente?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar"
        }).then((result) => {
      
            if(result.value){
      
                api.deleteClient(id).then((res) => {
      
                  const Toast = window.swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', window.swal.stopTimer)
                      toast.addEventListener('mouseleave', window.swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({icon:'success',title:'O cliente foi removido.'});
                  getClients();
                  
                }).catch(error => {
      
                    if(error !== undefined){
      
                        if(error.response !== undefined){
      
                            if (error.response.data !== undefined){
      
                                if(error.response.data.msg !== undefined){
                                
                                    const Toast = window.swal.mixin({
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', window.swal.stopTimer)
                                            toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                        }
                                    })
                                    
                                    Toast.fire({icon:'warning',title:error.response.data.msg});
                                  
                                }
      
                            }
      
                        }
      
                    }
      
                });
      
            }
      
        });
      
    });
    
    useEffect(() => {

        getClients();
        return (() => { });

    },[]);

    return (<React.Fragment>
                
        <div className="container-fluid py-4">
            <div className="d-sm-flex justify-content-between">
                <div></div>
                <div className="d-flex">
                    <button hidden className="btn btn-outline-dark">Filtros</button>
                    <button hidden className="btn btn-icon btn-outline-dark ms-2 export" data-type="csv" type="button">Exportar <i className="fas fa-download"></i></button>
                    <Link to="/clients/new" className="btn btn-icon bg-gradient-success ms-2"><i className="fas fa-user-plus"></i> Cadastrar cliente</Link>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Clientes</h5>
                            
                        </div>
                        <div className="table-responsive">
                            <table className="table align-items-center mb-0" style={{overflowY:'clip'}}>
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"></th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">#</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"></th> 
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">CPF</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Email</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Data de nascimento</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Data de cadastro</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"><center>Administrador</center></th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"><center>V.F E-mail</center></th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"><center>V.F Manual</center></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        clientList.map((value,index) => {
  
                                            return( <tr key={'id_'+value.id}>
                                                        <td>
                                                            <Link style={{marginBottom:'0px'}} to={'/clients/'+value.id} className="btn btn-sm bg-gradient-default ">Editar</Link>

                                                            {

                                                                (window.user.id !== value.id) &&
                                                                <button style={{marginBottom:'0px'}} onClick={() => remover(value.id)} className="btn btn-sm bg-gradient-danger ms-2">Remover</button>
                                                            
                                                            }
                                                            
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.id}</p>
                                                        </td>
                                                        <td style={{position:'relative'}}>
                                                            <Link to={'/clients/'+value.id} className="">
                                                                <div className="d-flex px-2 py-1">
                                                                    <div><img src="/img/download.jpg" className="avatar avatar-sm me-3" alt="avatar" /></div>
                                                                    <div className="d-flex flex-column justify-content-center"><h6 className="mb-0 font-weight-normal text-sm">{value.name} {value.last_name}</h6></div>
                                                                </div>
                                                            </Link>

                                                            <div>

                                                                <div style={{display:'flex',gap:'5px',position:'absolute',left:'66px',top:'40px'}}>
                                                                    <p className="text-sm font-weight-normal mb-0">
                                                                    {

                                                                        (value.manualCheck !== 1 && value.manualCheckDescription !== "" && value.manualCheckDescription !== null) &&
                                                                        <React.Fragment>
                                                                            <Tippy content={<span>Verficação manual: {
                                                                                
                                                                                    (value.manualCheckDescription !== null) &&
                                                                                    <React.Fragment>{value.manualCheckDescription}</React.Fragment>

                                                                                }</span>}>
                                                                                <font><i style={{color:"red"}} className="far fa-times-circle"></i></font>
                                                                            </Tippy>
                                                                        </React.Fragment>
                                                                            

                                                                    }
                                                                    {

                                                                        (value.manualCheck !== 1 && (value.manualCheckDescription === "" || value.manualCheckDescription === null)) &&
                                                                        <React.Fragment>
                                                                            <Tippy content={<span>Verficação manual</span>}>
                                                                                <font><i style={{color:"#584b9f"}} className="far fa-minus-circle"></i></font>
                                                                            </Tippy>
                                                                        </React.Fragment>
                                                                        

                                                                    }
                                                                    {

                                                                        (value.manualCheck === 1) &&
                                                                        <React.Fragment>
                                                                            <Tippy content={<span>Verficação manual</span>}>
                                                                                <font><i style={{color:"green"}} className="far fa-check-circle"></i></font>
                                                                            </Tippy>
                                                                        </React.Fragment>

                                                                    }
                                                                    </p>
                                                                    <p className="text-sm font-weight-normal mb-0">
                                                                        {

                                                                            (value.email_verify !== 1) &&
                                                                            <React.Fragment>
                                                                                <Tippy content={<span>Verficação do e-mail</span>}>
                                                                                    <font><i style={{color:"red"}} className="far fa-times-circle"></i></font>
                                                                                </Tippy>
                                                                            </React.Fragment>

                                                                        }
                                                                        {

                                                                            (value.email_verify === 1) &&
                                                                            <React.Fragment>
                                                                                <Tippy content={<span>Verficação do e-mail</span>}>
                                                                                    <font><i style={{color:"green"}} className="far fa-check-circle"></i></font>
                                                                                </Tippy>
                                                                            </React.Fragment>

                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.cpf}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.email}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                                {

                                                                    (value.birth_date !== null && value.birth_date !== "") &&
                                                                    <React.Fragment>{moment(value.birth_date,'YYYY-MM-DD').format('DD/MM/YYYY')}</React.Fragment>
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                                {
                                                                    <React.Fragment>{moment(value.createDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</React.Fragment>
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <center>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                            {

                                                                (value.admin !== 1) &&
                                                                <React.Fragment><font style={{}}>NÃO</font></React.Fragment>

                                                            }
                                                            {

                                                                (value.admin === 1) &&
                                                                <React.Fragment><font style={{fontWeight:"700",color:'red'}}>SIM</font></React.Fragment>

                                                            }
                                                            </p>
                                                            </center>
                                                        </td>
                                                        <td>
                                                            <center>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                            {

                                                                (value.email_verify !== 1) &&
                                                                <React.Fragment><font><i style={{color:"red"}} className="far fa-times-circle"></i></font></React.Fragment>

                                                                }
                                                            {

                                                                (value.email_verify === 1) &&
                                                                <React.Fragment><font><i style={{color:"green"}} className="far fa-check-circle"></i></font></React.Fragment>

                                                            }
                                                            </p>
                                                            </center>
                                                        </td>
                                                        <td>
                                                            <center>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                            {

                                                                (value.manualCheck !== 1) &&
                                                                <React.Fragment><font><i style={{color:"red"}} className="far fa-times-circle"></i></font></React.Fragment>

                                                                }
                                                            {

                                                                (value.manualCheck === 1) &&
                                                                <React.Fragment><font><i style={{color:"green"}} className="far fa-check-circle"></i></font></React.Fragment>

                                                            }
                                                            </p>
                                                            </center>
                                                        </td>
                                                    </tr> )

                                        })

                                    }
                                </tbody>
                            </table>
                            {

                                (load === false) &&
                                <React.Fragment>
                                    <div style={{display:'flex',justifyContent:'center',margin:'50px 0px'}}><ClipLoader color="#358eed"/></div>
                                </React.Fragment>

                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </React.Fragment>)

}