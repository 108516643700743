import React from "react";

import Menu from "./dashboard/Menu.jsx";
import {Routes,Route} from "react-router-dom";

import Home from "./dashboard/Home.jsx";
import Coins from "./dashboard/Coins.jsx";
import Clients from "./dashboard/Clients.jsx";
//import Account from "./dashboard/Account.jsx";
import Transations from "./dashboard/Transations.jsx";
import RegisterClient from "./dashboard/RegisterClient.jsx";
import Report from "./dashboard/Report.jsx";
import Purchase from "./dashboard/Purchase.jsx";
import Template from "./dashboard/Template.jsx";

export default function DashBoard(props){

    //console.log(props);
    //console.log(props.math);

    return(
    <React.Fragment>
        
        <style>{' body{ background-color: #f0f2f5; }'}</style>
        <Menu/>

        <main className="main-content  border-radius-lg ps ps--active-y">
            <div className="container-fluid py-4">
                <Routes>
                    <Route path="/" element={<Home />}/> 
                    <Route path="/coins" element={<Coins />} />
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/clients/:id" element={<RegisterClient />} />
                    <Route path="/account" element={<RegisterClient my={true} />} />
                    <Route path="/transations" element={<Transations />} />
                    <Route path="/report" element={<Report />} />
                    <Route path="/purchase" element={<Purchase />} />
                    <Route path="/template" element={<Template />} />
                     
                </Routes>
            </div>
        </main>
    </React.Fragment>)

}
 