import React, {useEffect,useState} from "react";
import {useParams} from "react-router-dom";
import InputMask from 'react-input-mask';
import api from "../../Api.js";
import moment from "moment";
import { useNavigate } from 'react-router-dom'; 
import {Modal} from "reactstrap";
import TransationTable from "./TransationTable.jsx";
import SendMail from "./SendMail.jsx";

export default function RegisterClient(props){

    const {id} = useParams();
    const [dialog,setDialog] = useState({});
    const [modalNg,setModalNg] = useState(false);
    const [modalSendMail,setModalSendMail] = useState(false);
     
    let navigate = useNavigate();
    
    const postClient = (() => {

        var obj = JSON.parse(JSON.stringify(dialog));
        if(obj.birth_date !== null && obj.birth_date !== undefined && obj.birth_date !== ""){

            obj.birth_date = moment(obj.birth_date,'DD/MM/YYYY').format('YYYY-MM-DD');

        }
        
        obj.email_verify = parseInt(obj.email_verify);
        obj.admin = parseInt(obj.admin);
        obj.block = parseInt(obj.block);

        console.log(obj);
        
        api.postClient(obj).then(async (res) => { 

            setDialog(res.data);

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'success',title:'O cliente foi cadastrado.'});
            
        }).catch((error) => {

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:error.response.data.msg});

        });
 

    });

    const remover = ((id) => {

        window.swal.fire({
            position:"center",
            toast: true,
            title: 'Você deseja deletar o cliente?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar"
        }).then((result) => {
      
            if(result.value){
      
                api.deleteClient(id).then((res) => {
      
                  const Toast = window.swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', window.swal.stopTimer)
                      toast.addEventListener('mouseleave', window.swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({icon:'success',title:'O cliente foi removido.'});
                  navigate('/clients');
                  
                }).catch(error => {
      
                    if(error !== undefined){
      
                        if(error.response !== undefined){
      
                            if (error.response.data !== undefined){
      
                                if(error.response.data.msg !== undefined){
                                
                                    const Toast = window.swal.mixin({
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', window.swal.stopTimer)
                                            toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                        }
                                    })
                                    
                                    Toast.fire({icon:'warning',title:error.response.data.msg});
                                  
                                }
      
                            }
      
                        }
      
                    }
      
                });
      
            }
      
        });
      
    });
    

    const putClient = (() => {

        var obj = JSON.parse(JSON.stringify(dialog));
        if(obj.birth_date !== null && obj.birth_date !== undefined && obj.birth_date !== ""){

            obj.birth_date = moment(obj.birth_date,'DD/MM/YYYY').format('YYYY-MM-DD');

        }

        obj.email_verify = parseInt(obj.email_verify);
        obj.admin = parseInt(obj.admin);
        obj.block = parseInt(obj.block);

        if(obj.password === ""){ delete obj.password; }

        api.putClient(obj.id,obj).then(async (res) => { 

            res.data.birth_date = moment(res.data.birth_date,'YYYY-MM-DD').format('DD/MM/YYYY');
            res.data.password = "";

            console.log('resposta recebida');
            console.log(res.data);

            setDialog(res.data);

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'success',title:'As alterações foram salvas.'});

        }).catch((error) => {

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:error.response.data.msg});

        });

    });

    const cep = ((zip_code) => {

        api.cep(zip_code).then(async (res) => {

            setDialog({...dialog,zip_code:zip_code,city:res.data.localidade,state:res.data.uf,street:res.data.logradouro});

        })

    });


     

    useEffect(() => {

        if(props.my === true){

            var data = JSON.parse(JSON.stringify(window.user));
            if(data.birth_date !== null){

               data.birth_date = moment(data.birth_date,'YYYY-MM-DD').format('DD/MM/YYYY'); 

            }
            
            setDialog(data);

        }

        if(id !== "new" && id !== undefined){

            api.getClient(id).then(async (res) => {
                
                if(res.data.birth_date !== null){

                    res.data.birth_date = moment(res.data.birth_date,'YYYY-MM-DD').format('DD/MM/YYYY');
                }
                
                if(res.data.admin !== 1){ res.data.admin = 0; }
                if(res.data.email_verify !== 1){ res.data.email_verify = 0; }
                if(res.data.block !== 1){ res.data.block = 0; }

                setDialog(res.data);

            });

        }

        return (() => { });

    },[id,props.my]);

    useEffect(() => {

        window.putClient = putClient;

        return(() => { delete window.putClient; });

    });

     
    
    return(
    <React.Fragment>

        {

            (props.my === true) &&
            <style>{' input{ pointer-events:none; } '}</style>

        }


        <div className="container-fluid my-3 py-3">
            <div className="row mb-5">

                <style>{' .ds1{ border-radius: 20px; cursor:pointer; }  .ds1:hover{background: #f7f7f7;} '}</style>

                {

                    (dialog.id !== undefined && props.my !== true) &&
                    <div className="col-lg-3 animate__animated animate__backInLeftdd">
                        <div className="card position-sticky top-1">
                            <ul className="nav flex-column bg-white border-radius-lg p-3">
                            <li className="nav-item" onClick={() => { setModalNg(true); }}>
                                    <font className="nav-link text-dark d-flex ds1">
                                        <i className="fas fa-hand-holding-usd"></i> <span className="text-sm ms-2">Negociações</span>
                                    </font>
                                </li>
                                <li className="nav-item pt-2" onClick={() => { setModalSendMail(true); }}>
                                    <font className="nav-link text-dark d-flex ds1">
                                        <i className="fas fa-at"></i> <span className="text-sm ms-2">Enviar e-mail</span>
                                    </font>
                                </li>
                                <li className="nav-item pt-2">
                                    {

                                        (dialog.block !== 1) &&
                                        <React.Fragment><font onClick={() => { setDialog({...dialog,block:1}); setTimeout(() => { window.putClient(); }, 100); }} className="nav-link text-dark d-flex ds1"><i className="fas fa-lock"></i> <span className="text-sm ms-2"> Bloquear conta</span></font></React.Fragment>

                                    }
                                    {

                                        (dialog.block === 1) &&
                                        <React.Fragment><font onClick={() => {setDialog({...dialog,block:0}); setTimeout(() => { window.putClient(); }, 100); }} className="nav-link text-dark d-flex ds1"><i className="fas fa-lock-open"></i> <span className="text-sm ms-2"> Desbloquear conta</span></font></React.Fragment>

                                    }
                                </li>
                                <li className="nav-item pt-2">
                                    <font onClick={() => { remover(dialog.id); }} className="nav-link text-dark d-flex ds1" style={{color:"red"}}>
                                    <i className="far fa-trash-alt" style={{color:"red"}}></i> <span style={{color:"red"}} className="text-sm ms-2">Deletar conta</span>
                                    </font>
                                </li>
                            </ul>
                        </div>
                    </div>

                }
                
                <div className={(dialog.id === undefined || props.my === true ? 'col-md-12':'animate__animated animate__backInRightdd col-md-9')}>
                    <form onSubmit={(e) => { e.preventDefault(); if(dialog.id === undefined){ postClient(); } if(dialog.id !== undefined){ putClient(); } }}>
                        <div className="card">
                            <div className="card-header">
                                <h5>Informações</h5>
                            </div>
                            
                            <div className="card-body pt-0">
                                <div className="row">
                                    <h6>Pessoal</h6>
                                    <div className="col-md-4 pt-2">
                                        <div className="input-group input-group-static">
                                            <label>Nome</label>
                                            <input type="text" className="form-control" value={dialog.name} onChange={(e) => { setDialog({...dialog,name:e.target.value}); }} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 pt-2">
                                        <div className="input-group input-group-static">
                                            <label>Sobrenome</label>
                                            <input type="text" className="form-control" value={dialog.last_name} onChange={(e) => { setDialog({...dialog,last_name:e.target.value}); }} />
                                        </div>
                                    </div>

                                    {

                                        (props.my !== true) &&
                                        <div className="col-md-4 pt-2">
                                            <div className="input-group input-group-static">
                                                <label>Nome no banco</label>
                                                <input type="text" className="form-control" value={dialog.bank_name} onChange={(e) => { setDialog({...dialog,bank_name:e.target.value}); }} />
                                            </div>
                                        </div>

                                    }
                                    <div className="col-md-4 pt-2">
                                        <div className="input-group input-group-static">
                                            <label>Data de nascimento</label>
                                            <InputMask value={dialog.birth_date} onChange={(e) => { setDialog({...dialog,birth_date:e.target.value}); }} className="form-control" mask="99/99/9999" maskChar=" "  />
                                        </div>
                                    </div>

                                    {

                                        (props.my !== true) && 
                                        <div className="col-md-4 pt-2">
                                            <div className="input-group input-group-static">
                                                <label>CPF</label>
                                                <input value={dialog.cpf} onChange={(e) => { setDialog({...dialog,cpf:e.target.value}); }} className="form-control"  maskChar=" "  />
                                            </div>
                                        </div>

                                    }
                                    
                                    <h6 className="pt-4">Endereço</h6>
                                    <div className="col-md-4 pt-2">
                                        <div className="input-group input-group-static">
                                            <label>CEP</label>
                                            <InputMask value={dialog.zip_code} onChange={(e) => { if(e.target.value.length === 9){ cep(e.target.value); } setDialog({...dialog,zip_code:e.target.value}); }} className="form-control" mask="99999-999" maskChar=" "  />
                                        </div>
                                    </div>

                                    <div className="col-md-8 pt-2">
                                        <div className="input-group input-group-static">
                                            <label>Rua</label>
                                            <input type="text" className="form-control" value={dialog.street} onChange={(e) => { setDialog({...dialog,street:e.target.value}); }} />
                                        </div>
                                    </div>

                                    <div className="col-md-4 pt-4">
                                        <div className="input-group input-group-static">
                                            <label>Estado</label>
                                            <input type="text" className="form-control" value={dialog.state} onChange={(e) => { setDialog({...dialog,state:e.target.value}); }} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 pt-4">
                                        <div className="input-group input-group-static">
                                            <label>Cidade</label>
                                            <input type="text" className="form-control" value={dialog.city} onChange={(e) => { setDialog({...dialog,city:e.target.value}); }} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 pt-4">
                                        <div className="input-group input-group-static">
                                            <label>Número</label>
                                            <input type="text" className="form-control" value={dialog.number} onChange={(e) => { setDialog({...dialog,number:e.target.value}); }} />
                                        </div>
                                    </div>

                                    <h6 className="pt-4">Contato</h6>
                                    <div className="col-md-4 pt-4">
                                        <div className="input-group input-group-static">
                                            <label>E-mail</label>
                                            <input type="text" className="form-control" value={dialog.email} onChange={(e) => { setDialog({...dialog,email:e.target.value}); }} />
                                            {

                                                (dialog.email !== undefined && dialog.email !== "" && dialog.email !== null) &&
                                                <a rel="noopener noreferrer" href={'mailto:'+dialog.email} target="_blank">Link e-mail</a>

                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4 pt-4">
                                        <div className="input-group input-group-static">
                                            <label>Telefone/celular</label>
                                            <InputMask value={dialog.phone} onChange={(e) => { setDialog({...dialog,phone:e.target.value}); }} className="form-control" mask="(99) 9999-9999999" maskChar=" "  />
                                            
                                            {

                                                (dialog.phone !== undefined && dialog.phone !== "" && dialog.phone !== null) &&
                                                <a rel="noopener noreferrer" href={'https://wa.me/55'+dialog.phone.replace(/[^0-9]/g,'')} target="_blank">Link whatsapp</a>

                                            }
                                            
                                        </div>
                                    </div>

                                    {

                                        (props.my !== true) &&
                                        <React.Fragment>
                                            <h6 className="pt-4">Acesso</h6>
                                            <div className="col-md-3 pt-4">
                                                <div className="input-group input-group-static">
                                                    <label>E-mail verificado</label>
                                                    <select type="text" className="form-control" value={dialog.email_verify} onChange={(e) => { setDialog({...dialog,email_verify:e.target.value}); }} >
                                                        <option value=""></option>
                                                        <option value="0">Não</option>
                                                        <option value="1">Sim</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 pt-4">
                                                <div className="input-group input-group-static">
                                                    <label>Verificação manual</label>
                                                    <select type="text" className="form-control" value={dialog.manualCheck} onChange={(e) => { setDialog({...dialog,manualCheck:e.target.value}); }} >
                                                        <option value=""></option>
                                                        <option value="0">Não</option>
                                                        <option value="1">Sim</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 pt-4">
                                                <div className="input-group input-group-static">
                                                    <label>Descrição verificação manual</label>
                                                    <textarea type="text" className="form-control" value={dialog.manualCheckDescription} onChange={(e) => { setDialog({...dialog,manualCheckDescription:e.target.value}); }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4 pt-4">
                                                <div className="input-group input-group-static">
                                                    <label>Senha</label>
                                                    <input type="password" className="form-control" value={dialog.password} onChange={(e) => { setDialog({...dialog,password:e.target.value}); }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3 pt-4">
                                                <div className="input-group input-group-static">
                                                    <label>Bloquear acesso</label>
                                                    <select type="text" className="form-control" value={dialog.block} onChange={(e) => { setDialog({...dialog,block:e.target.value}); }} >
                                                        <option value=""></option>
                                                        <option value="0">Não</option>
                                                        <option value="1">Sim</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 pt-4">
                                                <div className="input-group input-group-static">
                                                    <label>Acesso de administrador</label>
                                                    <select type="text" className="form-control" value={dialog.admin} onChange={(e) => { setDialog({...dialog,admin:e.target.value}); }} >
                                                        <option value=""></option>
                                                        <option value="0">Não</option>
                                                        <option value="1">Sim</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </React.Fragment>

                                    }
                                     

                                </div>

                                {

                                    (dialog.id === undefined) &&
                                    <button type="submit" className="btn bg-gradient-dark btn-sm float-end mt-6 mb-0">Cadastrar cliente</button>

                                }

                                {

                                    (dialog.id !== undefined && props.my !== true) &&
                                    <button type="submit" className="btn bg-gradient-dark btn-sm float-end mt-6 mb-0">Salvar alterações</button>

                                }
                                
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>


        <Modal className="modal-dialog-centered" style={{maxWidth:'99%'}}  isOpen={modalNg} toggle={() => setModalNg(false)} >
            <div className="modal-header">
                <h5 className="modal-title">Histórico de transações</h5> 
                <div>
                    <button className="btn btn-icon bg-gradient-success ms-2" style={{marginBottom:'0px'}} onClick={() => { window.newTransation(); }}><i className="fas fa-plus"></i> Cadastrar transação</button>
                    <button className="btn btn-icon bg-gradient-secondary ms-2" style={{marginBottom:'0px'}} onClick={() => { setModalNg(false); }}><i className="fas fa-plus"></i> Fechar</button>
                </div>
            </div>
            <div className="modal-body">
                {

                    (modalNg === true) &&
                    <React.Fragment>
                        <style>{' .th_cliente{ display:none; } .td_cliente{ display:none; } '}</style>
                        <TransationTable client={dialog.id} />
                    </React.Fragment>

                }
            </div>
        </Modal>

        <Modal className="modal-dialog-centered" style={{ }}  isOpen={modalSendMail} toggle={() => setModalSendMail(false)} >
            <div className="modal-header">
                <h5 className="modal-title">Enviar e-mail</h5> 
                <div>
                    <button className="btn btn-icon bg-gradient-secondary ms-2" style={{marginBottom:'0px'}} onClick={() => { setModalSendMail(false); }}><i className="fas fa-times"></i> Fechar</button>
                </div>
            </div>
            <div className="modal-body">
                {

                    (modalSendMail === true) &&
                    <React.Fragment>
                        <style>{' .th_cliente{ display:none; } .td_cliente{ display:none; } '}</style>
                        <SendMail client={dialog.id} />
                    </React.Fragment>

                }
            </div>
        </Modal>


         


    </React.Fragment>)

}
 