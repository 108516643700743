import React,{useState,useEffect} from "react";
import api from "./../../Api.js";
import Cliente from "./Report/Cliente.jsx";
import Pedidos from "./Report/Pedidos.jsx";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import InputMask from 'react-input-mask';

export default function Transations(){
    
    const [startDate,setStartDate] = useState('01'+moment().format('/MM/YYYY'));
    const [endDate,setEndDate] = useState(moment().endOf('month').format('DD')+moment().format('/MM/YYYY'));

    const [loadClients,setLoadClients] = useState(false);
    const [loadTransactions,setLoadTransactions] = useState(false);

    const [clients,setClients] = useState([]);
    const [transactions,setTransactions] = useState([]);

    const [coins,setCoins] = useState([]);
    const [loadCoins,setLoadCoins] = useState(false);

    const getCoins = (async (coin) => {

        await api.getCoins(coin).then(async (res) => { 
            
            setLoadCoins(true);
            setCoins(res.data);

        }).catch((error) => {});

    });

    const update = (() => {

        setLoadClients(false);
        setLoadTransactions(false);

        getClientReport();
        getInvoiceReport();

    });

    const getClientReport = (() => {

        api.getClientReport(moment(startDate,'DD/MM/YYYY').format('YYYY-MM-DD'),moment(endDate,'DD/MM/YYYY').format('YYYY-MM-DD')).then(response => {

            setClients(response.data);
            setLoadClients(true);

        }).catch(error => { alert(error.response.data.msg); setLoadClients(true); });

    });

    const getInvoiceReport = (() => {

        api.getInvoiceReport(moment(startDate,'DD/MM/YYYY').format('YYYY-MM-DD'),moment(endDate,'DD/MM/YYYY').format('YYYY-MM-DD')).then(response => {

            setTransactions(response.data);
            setLoadTransactions(true);

        }).catch(error => { alert(error.response.data.msg); setLoadClients(true); });

    });

    useEffect(()=> {

        update();
        getCoins();
        // eslint-disable-next-line
    },[])

    var disable = false;
    if(loadClients === false || loadTransactions === false){

        disable = true;

    }

  

    var pendentes = 0;
    var concluidas = 0;
    var lucro = 0;

    for(let index = 0; index < transactions.length; index++){
        
        var t = transactions[index];
        if(t.status === 'concluded'){ concluidas++; }
        if(t.status === 'pending'){ pendentes++; }
        if(t.profit !== null){ lucro = lucro+t.profit; }
        
    }

    return (
    <React.Fragment>
        <div className="container-fluid py-4">
              

            <div class="row">

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card  mb-2">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute">
                                <i style={{opacity:"1"}} class="fas fa-user-plus"></i>
                            </div>
                            <div class="text-end pt-1">
                                <p class="text-sm mb-0 text-capitalize">Novos clientes</p>
                                <h4 class="mb-0">
                                {
                                    
                                    (loadClients === false) &&
                                    <ClipLoader color="#358eed"/>

                                }
                                {

                                    (loadClients === true) &&
                                    clients.length

                                }
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card  mb-2">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl mt-n4 position-absolute">
                                <i style={{opacity:"1"}} class="fas fa-comment-dollar"></i>
                            </div>
                            <div class="text-end pt-1">
                                <p class="text-sm mb-0 text-capitalize">Transações pendentes</p>
                                <h4 class="mb-0">
                                {
                                    
                                    (loadTransactions === false) &&
                                    <ClipLoader color="#358eed"/>

                                }
                                {

                                    (loadTransactions === true) &&
                                    <React.Fragment>{pendentes}</React.Fragment>

                                }
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
 
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card  mb-2">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                <i style={{opacity:"1"}} class="fas fa-hand-holding-usd"></i>
                            </div>
                            <div class="text-end pt-1">
                                <p class="text-sm mb-0 text-capitalize">Transações Concluídas</p>
                                <h4 class="mb-0">
                                {
                                    
                                    (loadTransactions === false) &&
                                    <ClipLoader color="#358eed"/>

                                }
                                {

                                    (loadTransactions === true) &&
                                    <React.Fragment>{concluidas}</React.Fragment>

                                }
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card  mb-2">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                <i style={{opacity:"1"}} class="fas fa-wallet"></i>
                            </div>
                            <div class="text-end pt-1">
                                <p class="text-sm mb-0 text-capitalize">Lucro</p>
                                <h4 class="mb-0">
                                {
                                    
                                    (loadTransactions === false) &&
                                    <ClipLoader color="#358eed"/>

                                }
                                {

                                    (loadTransactions === true) &&
                                    <React.Fragment>R$ {lucro.toFixed(2)}</React.Fragment>

                                }
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div style={{display:'flex',justifyContent:'center',position:'relative'}}>
                    <div>
                        <div>Data inicial:</div>
                        <div><InputMask disabled={(disable === false ? false:true)} value={startDate} onChange={(e) => { setStartDate(e.target.value); }} style={{backgroundColor:"#fff"}} className="form-control inputfilter" mask="99/99/9999" maskChar=" "  /></div>
                    </div>
                    <div>
                        <div>Data final:</div>
                        <div><InputMask disabled={(disable === false ? false:true)} value={endDate} onChange={(e) => { setEndDate(e.target.value); }} style={{backgroundColor:"#fff"}} className="form-control inputfilter" mask="99/99/9999" maskChar=" "  /></div>
                    </div>
                    <button onClick={() => { update(); }} class="btn btn-sm bg-gradient-success ms-2" style={{position:'relative',height:'35px',top:'28px'}}>Atualizar</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <Cliente load={loadClients} list={clients} />
                </div>
                <div class="col-md-8">
                    <Pedidos load={loadTransactions} list={transactions} coins={coins} loadCoins={loadCoins} />
                </div>
            </div>
        </div>
    </React.Fragment>)

}