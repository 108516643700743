import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = (props) => {
 
  return (
    <div>
        <ReactQuill theme="snow" value={props.html} onChange={(d) => {

            console.log('d',d);
            props.onChange(d);

        }} /> 
    </div>
  );

};

export default Editor;
