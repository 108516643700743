import React,{useState,useEffect} from "react";
import api from "./../../Api.js";
import ClipLoader from "react-spinners/ClipLoader";
import {Button,Modal} from "reactstrap";

export default function Coins(){
//teste
    const [load,setLoad] = useState(false);

    const [openModal,setOpenModal] = useState(false);
    const [dialog,setDialog] = useState({});
    const [coins,setCoins] = useState([]);
 
    const getAllCoins = (async () => {

        setLoad(false);
        api.getAllCoins().then(async (res) => { 

            setLoad(true);
            setCoins(res.data);

        }).catch((error) => {

            setLoad(false);

        });

    });
 
    const remover = ((id) => {

        window.swal.fire({
            position:"center",
            toast: true,
            title: 'Você deseja remover a moeda?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar"
        }).then((result) => {
      
            if(result.value){
      
                api.deleteCoin(id).then((res) => {
      
                  const Toast = window.swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', window.swal.stopTimer)
                      toast.addEventListener('mouseleave', window.swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({icon:'success',title:'A moeda foi removida.'});
                  getAllCoins();
                  
                }).catch(error => {
      
                    if(error !== undefined){
      
                        if(error.response !== undefined){
      
                            if (error.response.data !== undefined){
      
                                if(error.response.data.msg !== undefined){
                                
                                    const Toast = window.swal.mixin({
                                        toast: true,
                                        position: 'top',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', window.swal.stopTimer)
                                            toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                        }
                                    })
                                    
                                    Toast.fire({icon:'error',title:error.response.data.msg});
                                  
                                }
      
                            }
      
                        }
      
                    }
      
                });
      
            }
      
        });
      
    });
       
    const postCoin = (() => {

        console.log('postCoin');
        var obj = JSON.parse(JSON.stringify(dialog)); 

        obj.status = parseInt(obj.status);
        obj.buy = parseInt(obj.buy);
        obj.sell = parseInt(obj.sell);
        obj.tax_buy = parseInt(obj.tax_buy);
        obj.tax_sell = parseInt(obj.tax_sell);

        console.log(obj);
        
        api.postCoin(obj).then(async (res) => { 

            setDialog(res.data);
            setOpenModal(false);
            getAllCoins();

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'success',title:'A moeda foi cadastrada.'});
            
        }).catch((error) => {

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:error.response.data.msg});

        });

    });

    const putCoin = (() => {

        console.log('postCoin');
        var obj = JSON.parse(JSON.stringify(dialog)); 

        obj.status = parseInt(obj.status);
        obj.buy = parseInt(obj.buy);
        obj.sell = parseInt(obj.sell);
        obj.tax_buy = parseInt(obj.tax_buy);
        obj.tax_sell = parseInt(obj.tax_sell);

        console.log(obj);
        
        api.putCoin(obj.id,obj).then(async (res) => { 

            setDialog(res.data);
            setOpenModal(false);
            getAllCoins();
            
            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'success',title:'As alterações foram salvas.'});
            
        }).catch((error) => {

            console.error(error);
            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:error.response.data.msg});

        });

    });

    useEffect(() => {

        getAllCoins();
        return (() => { });

    },[]);

    return (<React.Fragment>
                
        <div className="container-fluid py-4">
            <div className="d-sm-flex justify-content-between">
                <div></div>
                <div className="d-flex"> 
                    <button onClick={() => { setOpenModal(true); setDialog({}); }}className="btn btn-icon bg-gradient-success ms-2"><i className="fas fa-plus"></i> Cadastrar moeda</button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Moedas</h5>
                        </div>
                        <div className="table-responsive">
                            <table className="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">#</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Nome</th> 
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Sigla</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Carteira</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Status</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"><center>Comprar</center></th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"><center>Vender</center></th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"><center>Taxa de compra</center></th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"><center>Taxa de venda</center></th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        coins.map((value,index) => {
  
                                            return( <tr key={'id_'+value.id}>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.id}</p>
                                                        </td>
                                                        <td> 
                                                            <div className="d-flex px-2 py-1">
                                                                <div><img src={value.icon} className="avatar avatar-sm me-3" alt="avatar" /></div>
                                                                <div className="d-flex flex-column justify-content-center"><h6 className="mb-0 font-weight-normal text-sm">{value.name} {value.last_name}</h6></div>
                                                            </div> 
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.coin}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.cc_buy}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                            {

                                                                (value.status === 1) &&
                                                                <React.Fragment><font style={{fontWeight:"700",color:'GREEN'}}>DISPONÍVEL</font></React.Fragment>

                                                            }
                                                            {

                                                                (value.status !== 1) &&
                                                                <React.Fragment><font style={{fontWeight:"700",color:'red'}}>INDISPONÍVEL</font></React.Fragment>

                                                            }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <center>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                            {

                                                                (value.buy === 1) &&
                                                                <React.Fragment><font style={{fontWeight:"600",color:'GREEN'}}>SIM</font></React.Fragment>

                                                            }
                                                            {

                                                                (value.buy !== 1) &&
                                                                <React.Fragment><font style={{fontWeight:"600",color:'red'}}>NÃO</font></React.Fragment>

                                                            }
                                                            </p>
                                                            </center>
                                                        </td>
                                                        <td>
                                                            <center>
                                                            <p className="text-sm font-weight-normal mb-0">
                                                            {

                                                                (value.sell === 1) &&
                                                                <React.Fragment><font style={{fontWeight:"600",color:'GREEN'}}>SIM</font></React.Fragment>

                                                            }
                                                            {

                                                                (value.sell !== 1) &&
                                                                <React.Fragment><font style={{fontWeight:"600",color:'red'}}>NÃO</font></React.Fragment>

                                                            }
                                                            </p>
                                                            </center>
                                                        </td>
                                                        <td style={{fontWeight:'700'}}><center>{value.tax_buy}%</center></td>
                                                        <td style={{fontWeight:'700'}}><center>{value.tax_sell}%</center></td>
                                                        <td>
                                                            <button style={{marginBottom:'0px'}} onClick={() => { setOpenModal(true); setDialog(value); }} className="btn btn-sm bg-gradient-default ">Editar</button> 
                                                            <button style={{marginBottom:'0px'}} onClick={() => remover(value.id)} className="btn btn-sm bg-gradient-danger ms-2">Remover</button>
                                                        </td>
                                                    </tr> )

                                        })

                                    }
                                </tbody>
                            </table>
                            {

                                (load === false) &&
                                <React.Fragment>
                                    <div style={{display:'flex',justifyContent:'center',margin:'50px 0px'}}><ClipLoader color="#358eed"/></div>
                                </React.Fragment>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>















 
 

        <Modal className="modal-dialog-centered" isOpen={openModal} toggle={() => setOpenModal(false)} >
            <form onSubmit={(e) => { e.preventDefault(); if(dialog.id === undefined){ postCoin(); } if(dialog.id !== undefined){ putCoin(); } }}>
            <div className="modal-header">
                <h5 className="modal-title"> {(dialog.id === undefined ? 'Cadastrar moeda':'Editar moeda')} </h5> 
            </div>
            <div className="modal-body"> 
                <div className="row">
                    <div div className="col-md-4 pt-2">
                            <div className="input-group input-group-static">
                                <label>Nome</label>
                                <input type="text" className="form-control" value={dialog.name} onChange={(e) => { setDialog({...dialog,name:e.target.value}); }} />
                            </div>
                        </div>
                        <div div className="col-md-4 pt-2">
                            <div className="input-group input-group-static">
                                <label>Sigla</label>
                                <input type="text" className="form-control" value={dialog.coin} onChange={(e) => { setDialog({...dialog,coin:e.target.value}); }} />
                            </div>
                        </div>
                        <div div className="col-md-4 pt-2">
                            <div className="input-group input-group-static">
                                <label>Sigla MIN</label>
                                <input type="text" className="form-control" value={dialog.coinmin} onChange={(e) => { setDialog({...dialog,coinmin:e.target.value}); }} />
                            </div>
                        </div>
                        <div div className="col-md-12 pt-4">
                            <div className="input-group input-group-static">
                                <label>Cateira</label>
                                <input type="text" className="form-control" value={dialog.last_name} onChange={(e) => { setDialog({...dialog,last_name:e.target.value}); }} />
                            </div>
                        </div>
                        <div className="col-md-12 pt-4">
                            <div className="input-group input-group-static">
                                <label>Status</label>
                                <select type="text" className="form-control" value={dialog.status} onChange={(e) => { setDialog({...dialog,status:e.target.value}); }} >
                                    <option value=""></option>
                                    <option value="0">Indisponível</option>
                                    <option value="1">disponível</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 pt-4">
                            <div className="input-group input-group-static">
                                <label>Comprar</label>
                                <select type="text" className="form-control" value={dialog.buy} onChange={(e) => { setDialog({...dialog,buy:e.target.value}); }} >
                                    <option value=""></option>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 pt-4">
                            <div className="input-group input-group-static">
                                <label>Vender</label>
                                <select type="text" className="form-control" value={dialog.sell} onChange={(e) => { setDialog({...dialog,sell:e.target.value}); }} >
                                    <option value=""></option>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>
                        </div>
                        <div div className="col-md-6 pt-4">
                            <div className="input-group input-group-static">
                                <label>Taxa de compra %</label>
                                <input type="text" className="form-control" value={dialog.tax_buy} onChange={(e) => { setDialog({...dialog,tax_buy:e.target.value}); }} />
                            </div>
                        </div>
                        <div div className="col-md-6 pt-4">
                            <div className="input-group input-group-static">
                                <label>Taxa de venda %</label>
                                <input type="text" className="form-control" value={dialog.tax_sell} onChange={(e) => { setDialog({...dialog,tax_sell:e.target.value}); }} />
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="modal-footer">
                    <Button type="button" onClick={() => setOpenModal(false)} > Fechar</Button>
                    <Button type="submit" color="primary" >{(dialog.id === undefined ? 'Cadastrar moeda':'Salvar alterações')}</Button>
                </div>
            </form>
        </Modal>





    </React.Fragment>)

}