import React,{useEffect,useState} from "react";
import {Link} from "react-router-dom";
import api from "./../../Api.js";
//import { useNavigate } from 'react-router-dom';

export default function Menu(){

    const [update,setUpdate] = useState(0);
    const [show,setShow] = useState(false);
    const [responsive,setResponsive] = useState(false);

    const dd = (() => { setUpdate(update+1); })

    var menus = [];

    if(window.user !== undefined){

        if(window.user.admin === 1){

            menus = [
                {name:"Negociar",icon:"fas fa-hand-holding-usd",link:"/"},
                {name:"Minha conta",icon:"far fa-user-circle",link:"/account"},
                {name:"Relatório",icon:"fas fa-file-invoice-dollar",link:"/report"},
                {name:"Clientes",icon:"fas fa-users",link:"/clients"},
                {name:"Moedas",icon:"fas fa-coins",link:"/coins"},
                {name:"Transações",icon:"fas fa-file-invoice-dollar",link:"/transations"},
                {name:"Compras",icon:"fas fa-file-invoice-dollar",link:"/purchase"},
                {name:"Templates de email",icon:"fas fa-file",link:"/template"},
            ];  

        }
        
        if(window.user.admin !== 1){

            menus = [
                {name:"Negociar",icon:"fas fa-hand-holding-usd",link:"/"},
                {name:"Minha conta",icon:"far fa-user-circle",link:"/account"},
                {name:"Transações",icon:"fas fa-file-invoice-dollar",link:"/transations"},
            ]; 

        }

        //menus.push({name:"NOVO MENU",icon:"fas fa-file-invoice-dollar",link:"/account"});

    }
    

    const logout = (() => {

        api.logout().then(async (res) => { }).catch((error) => {});
        delete window.user;
        window.cookies.remove('invoice_startLimit',{path:'/'});
        setTimeout(() => { window.update(); }, 100);

    });

    const onresize = ((data) => {

        console.log('onresize');
        var width = window.innerWidth;
//        var height = window.innerHeight;

        if(width >= 1200){ setResponsive(false); setShow(true);  }
        if(width < 1200){ setResponsive(true);  }
         

    });

    useEffect(() => {

        onresize();

        window.addEventListener("resize", onresize);

        return(() => {

            window.removeEventListener("resize", onresize);

        });

    },[]);

    console.log('deu render menu');
    console.log('show',show);
    console.log('responsive',responsive);

    if((show !== true && responsive === false) || show === false){ 
        
        return <React.Fragment>

            {

                (responsive === true) &&
                <link href="/css/responsivo.css" rel="stylesheet" />

            }

            <div onClick={() => { setShow(true); }} style={{cursor:'pointer',position:'absolute',left:'17px',top:'7px',fontSize:'25px'}}><i class="fas fa-bars"></i></div>

            </React.Fragment>; 

    }

     
    return (<React.Fragment>

                {

                    (responsive === true) &&
                    <link href="/css/responsivo.css" rel="stylesheet" />

                }
                

                <aside onClick={() => { 

                    console.log('click aside');
                    console.log('responsive: ',responsive);
                    if(responsive === true){

                        setShow(false);

                    }

                }} className="animate__animated animate__slideInLeft sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark ps bg-white" id="sidenav-main">
                    <div className="sidenav-header" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                       <img src="/img/logos/logo_armata_1.png" className="navbar-brand-img h-100" alt="main_logo" />
                    </div>
                    <div className="collapse navbar-collapse ps" id="sidenav-collapse-main" style={{height:'100%'}}>
                        <ul className="navbar-nav">
                            {

                                menus.map((value,index) => {

                                    var ativo = ""
                                    if(window.location.pathname.indexOf(value.link) !== -1){

                                        ativo = "bg-gradient-info";

                                    }

                                    if(window.location.pathname !== "/" && value.link === "/"){ ativo = ""; }

                                    return (<React.Fragment key={"menu_"+index}>
                                        <li className="nav-item" >
                                            <Link to={value.link} onClick={() => { dd(); }}>
                                                <font style={{cursor:'pointer'}} className={"nav-link text-white "+ativo} >
                                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                                    <i className={value.icon}></i>
                                                    </div>
                                                    <span className="nav-link-text ms-1">{value.name}</span>
                                                </font>
                                            </Link>
                                        </li>
                                    </React.Fragment>)
                                    
                                })

                            }
                        </ul>
                    </div>
                    <div className="sidenav-footer position-absolute w-100 bottom-0 ">
                        <div className="mx-3">
                            <button className="btn bg-gradient-primary mt-4 w-100"  type="button" onClick={() => { logout(); }}><i className="fas fa-sign-out-alt"></i> Sair</button>
                        </div>
                    </div>
                </aside>
    </React.Fragment>)

}