import React from "react";
import TransationTable from "./TransationTable.jsx";
//import api from "./../../Api.js";

export default function Transations(){
    
    return (
    <React.Fragment>
            <div className="container-fluid py-4">
                <div className="d-sm-flex justify-content-between"><div></div>
                    <div className="d-flex">
                        {

                            (window.user.admin === 1) &&
                            <React.Fragment>
                                <button onClick={() => { window.getTransactions(); }} className="btn btn-icon bg-gradient-secondary ms-2"><i className="fas fa-sync"></i> Atualizar</button>
                                <button onClick={() => { window.filterTransation(); }} className="btn btn-icon bg-gradient-secondary ms-2"><i className="fas fa-filter"></i> Filtrar</button>
                                <button onClick={() => { window.exportTransation(); }} className="btn btn-icon bg-gradient-success ms-2"><i className="far fa-file-excel"></i> Exportar</button>
                                <button onClick={() => { window.exportTransationContador(); }} className="btn btn-icon bg-gradient-success ms-2"><i className="far fa-file-excel"></i> Exportar count.</button>
                                <button onClick={() => { window.newTransation(); }} className="btn btn-icon bg-gradient-success ms-2"><i className="fas fa-plus"></i> Cadastrar transação</button>
                            </React.Fragment>
                            
                        }
                        
                    </div>
                </div>
                <div className=" ">
                    <div className="col-lg-12 position-relative">
                        <div className="card mb-4 ">
                        <div className="d-flex">
                            <div className="icon icon-shape icon-lg bg-gradient-success shadow text-center border-radius-xl mt-n3 ms-4"><i className="material-icons opacity-10" aria-hidden="true"><i className="fas fa-file-invoice-dollar"></i></i></div>
                            <h6 className="mt-3 mb-2 ms-3 ">Histórico de transações</h6>
                        </div>
                        <div className=" ">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="table-responsive">
                                        <TransationTable />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-5"></div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
         
    </React.Fragment>)

}