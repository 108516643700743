import React,{useState,useEffect} from "react";
import api from "./../../Api.js";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import {Button,Modal} from "reactstrap";
import Select from 'react-select';
import InputMask from 'react-input-mask';
import {Link} from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import XLSX from 'xlsx'; 

const bancos = require('./../../banco_codigo.json');

export default function TransationTable(props){
        
    const [transactions,setTransactions] = useState([]); 
    window.transactions = transactions;

    const [search,setSearch] = useState([]);
    const [coins,setCoins] = useState([]);
    const [loadTransaction,setLoadTransaction] = useState(false);
    const [loadCoins,setLoadCoins] = useState(false);
    const [dialog,setDialog] = useState({});
    const [openModal,setOpenModal] = useState(false);
    const [openFilter,setOpenFilter] = useState(false);

    var std = '01/'+moment().format('MM/YYYY');
    var endd = moment( ).endOf('month').format('DD')+'/'+moment().format('MM')+'/'+moment().format('YYYY');

    const [startDate,setStartDate] = useState(std);
    const [endDate,setEnd] = useState(endd);
    const [statusFilter,setStatusFilter] = useState('');
    const [openQr,setOpenQr] = useState({}); 

    window.filterTransation = (() => { setOpenFilter(true); });

        const geraXlsx = (() => {
  
        var letras = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL'];
   
        var wb = JSON.parse('{"SheetNames":["Faturas"],"Sheets":{"Faturas":{} } }');
  
        var segura = 1;
        var segurasl = 0;
        
        //var invoices = $('.listItems').find('tr');
        //console.log('invoices: '+invoices.length);

        for(let index = 0; index < transactions.length; index++){
        
            var invoice = transactions[index];
            console.log(invoice);

            var sl = 0;

            segura++;
            var n = segura;

            
            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'ID da transação'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.id};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Nome'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.dataClient.name+' '+invoice.dataClient.last_name};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Nome bancário'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.dataClient.bank_name};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'CPF'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.dataClient.cpf};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'E-mail'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.dataClient.email};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Telefone'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.dataClient.phone};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Moeda'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:returnSigla(invoice.coin)};
            sl++;
 

            var status;
            if(invoice.status === "pending"){ status = "Pendente"; }
            if(invoice.status === "concluded"){ status = "Concluído"; }
            if(invoice.status === "paid"){ status = "Pago"; }
            if(invoice.status === "canceled"){ status = "Cancelado"; }
 
            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Status'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:status};
            sl++;


            var tipo;
            if(invoice.type === "buy"){ tipo = "Compra"; }
            if(invoice.type === "sell"){ tipo = "Venda"; } 
 
            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Tipo'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:tipo};
            sl++;


            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Quantidade de moedas'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.coin_amount};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Valor em reais'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'n',v:invoice.brl};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Cotação'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'n',v:invoice.currentQuote};
            sl++;
    

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Data de pagamento'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:(invoice.date_paid === null ? '':moment(invoice.date_paid,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'))};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Data de envio'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:(invoice.date_delivery === null ? '':moment(invoice.date_delivery,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'))};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Data do pedido'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:(invoice.createDate === null ? '':moment(invoice.createDate,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'))};
            sl++;
            
            console.log('ds1');
            if(sl > segurasl){ segurasl = sl; }
            var ultimoIndex = n;
  
        }
        
        ultimoIndex++;
        ultimoIndex++;
  
        
        console.log('ds2');

        if(segurasl === 0){ console.log('saiu dda'); return false; }

        console.log('ds3');

        console.log('Segura sl:'+segurasl)
        wb.Sheets['Faturas']['!ref'] = 'A1:'+letras[segurasl]+''+(ultimoIndex+6);


      
        wb.Sheets['Faturas']['!cols'] = [{width:10},{width:20},{width:20},{width:20},{width:20},{width:20},{width:10},{width:10},{width:10},{width:12},{width:15},{width:20},{width:20},{width:20} ];

        console.log('ds4');

        console.log(wb);

        var name = "Lista transações - ";
        
        name = name+' - '+moment().format('DD/MM/YYYY HH:mm:ss');
  
        XLSX.writeFile(wb,name+'.xlsx'); 
        
    });


    window.exportTransation = geraXlsx;




    const geraXlsxContadorCompra = (() => {

        var letras = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL'];
   
        var wb = JSON.parse('{"SheetNames":["Faturas"],"Sheets":{"Faturas":{} } }');
  
        var segura = 1;
        var segurasl = 0;
        
        //var invoices = $('.listItems').find('tr');
        //console.log('invoices: '+invoices.length);

        var transationsdd = [];
        for (let index = 0; index < transactions.length; index++) {
            
            if(transactions[index].status === "concluded" && transactions[index].type === "buy"){

                transationsdd.push(transactions[index]);

            }
            
        }

        for(let index = 0; index < transationsdd.length; index++){
        
            var invoice = transationsdd[index];
            console.log(invoice);

            var sl = 0;

            segura++;
            var n = segura;
  
            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Data'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:(invoice.date_delivery === null ? '':moment(invoice.date_paid,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'))};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Valor'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'n',v:invoice.brl};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'CPF/CNPJ'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.dataClient.cpf};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Nome'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.dataClient.bank_name};
            sl++;
  
            console.log('ds1');
            if(sl > segurasl){ segurasl = sl; }
            var ultimoIndex = n;
  
        }
        
        ultimoIndex++;
        ultimoIndex++;
  
        
        console.log('ds2');

        if(segurasl === 0){ console.log('saiu dda'); return false; }

        console.log('ds3');

        console.log('Segura sl:'+segurasl)
        wb.Sheets['Faturas']['!ref'] = 'A1:'+letras[segurasl]+''+(ultimoIndex+6);


      
        wb.Sheets['Faturas']['!cols'] = [{width:10},{width:20},{width:20},{width:20},{width:20},{width:20},{width:10},{width:10},{width:10},{width:12},{width:15},{width:20},{width:20},{width:20} ];

        console.log('ds4');

        console.log(wb);

        var name = "Lista de transações de compra";
  
        name = name+' - '+moment().format('DD/MM/YYYY HH:mm:ss');
  
        XLSX.writeFile(wb,name+'.xlsx'); 

    });



    const geraXlsxContadorVenda = (() => {
        var letras = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL'];
   
        var wb = JSON.parse('{"SheetNames":["Faturas"],"Sheets":{"Faturas":{} } }');
  
        var segura = 1;
        var segurasl = 0;
        
        //var invoices = $('.listItems').find('tr');
        //console.log('invoices: '+invoices.length);

        var transationsdd = [];
        for (let index = 0; index < transactions.length; index++) {
            
            if(transactions[index].status === "concluded" && transactions[index].type === "sell"){

                transationsdd.push(transactions[index]);

            }
            
        }

        for(let index = 0; index < transationsdd.length; index++){
        
            var invoice = transationsdd[index];
            console.log(invoice);

            var sl = 0;

            segura++;
            var n = segura;
  
            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Data'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:(invoice.date_delivery === null ? '':moment(invoice.date_paid,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'))};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Valor'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'n',v:invoice.brl};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'CPF/CNPJ'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.dataClient.cpf};
            sl++;

            wb.Sheets['Faturas'][''+letras[sl]+'1'] = {t:'s',v:'Nome'};
            wb.Sheets['Faturas'][''+letras[sl]+''+n+''] = {t:'s',v:invoice.dataClient.bank_name};
            sl++;
  
            console.log('ds1');
            if(sl > segurasl){ segurasl = sl; }
            var ultimoIndex = n;
  
        }
        
        ultimoIndex++;
        ultimoIndex++;
  
        
        console.log('ds2');

        if(segurasl === 0){ console.log('saiu dda'); return false; }

        console.log('ds3');

        console.log('Segura sl:'+segurasl)
        wb.Sheets['Faturas']['!ref'] = 'A1:'+letras[segurasl]+''+(ultimoIndex+6);


      
        wb.Sheets['Faturas']['!cols'] = [{width:10},{width:20},{width:20},{width:20},{width:20},{width:20},{width:10},{width:10},{width:10},{width:12},{width:15},{width:20},{width:20},{width:20} ];

        console.log('ds4');

        console.log(wb);

        var name = "Lista de transações de venda";
  
        name = name+' - '+moment().format('DD/MM/YYYY HH:mm:ss');
  
        XLSX.writeFile(wb,name+'.xlsx'); 

    });






    window.exportTransationContador = (() => {

        geraXlsxContadorCompra();
        geraXlsxContadorVenda();

    });

     















    const getTransactions = (async () => {

        console.log('props.client: '+props.client);
        if(props.client !== undefined){
            
            console.log('getTransactionsClient');
            await api.getTransactionsClient(props.client).then(async (res) => { 
                
                setLoadTransaction(true);
                setTransactions(res.data);
                
            }).catch((error) => {});

        }

        if(props.client === undefined){

            console.log('getTransactions');

            console.log('startDate '+startDate);
            console.log('endDate '+endDate);
            console.log('statusFilter '+statusFilter);


            var filters = "?teste=1";
            
            if(startDate.length === 10){

                filters = filters+'&startDate='+moment(startDate,'DD/MM/YYYY').format('YYYY-MM-DD');
                
            }
            
            if(endDate.length === 10){

                filters = filters+'&endDate='+moment(endDate,'DD/MM/YYYY').format('YYYY-MM-DD');

            }
            
            if(statusFilter !== ""){

                filters = filters+'&status='+statusFilter;

            }

            setLoadTransaction(false);
            await api.getTransactions(filters).then(async (res) => { 
                
                setLoadTransaction(true);
                setTransactions(res.data);
                
            }).catch((error) => {});

        }

    });


    const returnSigla = ((coin) => {

        var cds = coins.find(x => x.coin === coin);
        if(cds !== undefined){

            return cds.coinmin;

        }

        return '';
        

    });



    window.getTransactions = getTransactions;

    const getCoins = (async (coin) => {

        await api.getCoins(coin).then(async (res) => { 
            
            setLoadCoins(true);
            setCoins(res.data);

        }).catch((error) => {});

    });

    const postTransaction = (() => {

        var obj = JSON.parse(JSON.stringify(dialog));

        if(obj.date_paid !== null && obj.date_paid !== ""){ obj.date_paid = moment(obj.date_paid,'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'); }
        if(obj.date_delivery !== null && obj.date_delivery !== ""){ obj.date_delivery = moment(obj.date_delivery,'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'); }
        if(obj.createDate !== null && obj.createDate !== ""){ obj.createDate = moment(obj.createDate,'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'); }
 
        api.postTransaction(obj).then(async (res) => { 

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            });
                
            Toast.fire({icon:'success',title:'A transação foi cadastrada.'});
            setOpenModal(false);
            getTransactions();

        }).catch((error) => {
 
            if(error !== undefined){
      
                if(error.response !== undefined){

                    if (error.response.data !== undefined){

                        if(error.response.data.msg !== undefined){
                        
                            const Toast = window.swal.mixin({
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                }
                            })
                            
                            Toast.fire({icon:'error',title:error.response.data.msg});

                        }

                    }

                }

            }

        });

    });

    const putTransaction = (() => {

        var obj = JSON.parse(JSON.stringify(dialog));

        if(obj.date_paid !== null && obj.date_paid !== ""){ obj.date_paid = moment(obj.date_paid,'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'); }
        if(obj.date_delivery !== null && obj.date_delivery !== ""){ obj.date_delivery = moment(obj.date_delivery,'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'); }
        if(obj.createDate !== null && obj.createDate !== ""){ obj.createDate = moment(obj.createDate,'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'); }

        api.putTransaction(dialog.id,obj).then(async (res) => { 

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            });
                
            Toast.fire({icon:'success',title:'As alterações foram salvas.'});
            setOpenModal(false);
            getTransactions();

        }).catch((error) => {
 
            if(error !== undefined){
      
                if(error.response !== undefined){

                    if (error.response.data !== undefined){

                        if(error.response.data.msg !== undefined){
                        
                            const Toast = window.swal.mixin({
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                }
                            })
                            
                            Toast.fire({icon:'error',title:error.response.data.msg});

                        }

                    }

                }

            }

        });

    });

 

    const searchclient = ((search) => {

        api.searchclient(search).then(async (res) => { 

            setSearch(res.data);

        }).catch((error) => {});

    });

    useEffect(() => {
        
        getTransactions();
        getCoins();
         
        // eslint-disable-next-line
    },[]);

    useEffect(() => {

        window.newTransation = (() => {

            var obj = {};
            if(props.client !== undefined){

                obj.client = props.client;

            }
                
            setDialog(obj);
            setOpenModal(true);

        });

        return (() => {

            delete window.newTransation;

        });

    });

    const remover = ((id) => {

        window.swal.fire({
            position:"center",
            toast: true,
            title: 'Você deseja remover a transação?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar"
        }).then((result) => {
      
            if(result.value){
      
                api.deleteTransaction(id).then((res) => {
      
                  const Toast = window.swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', window.swal.stopTimer)
                      toast.addEventListener('mouseleave', window.swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({icon:'success',title:'A transação foi removida.'});
                  getTransactions();
                  
                }).catch(error => {
      
                    if(error !== undefined){
      
                        if(error.response !== undefined){
      
                            if (error.response.data !== undefined){
      
                                if(error.response.data.msg !== undefined){
                                
                                    const Toast = window.swal.mixin({
                                        toast: true,
                                        position: 'top',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', window.swal.stopTimer)
                                            toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                        }
                                    })
                                    
                                    Toast.fire({icon:'error',title:error.response.data.msg});
                                  
                                }
      
                            }
      
                        }
      
                    }
      
                });
      
            }
      
        });
      
    });


    const cancelOrder = ((id) => {

        window.swal.fire({
            position:"center",
            toast: true,
            title: 'Você deseja cancelar a transação?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar"
        }).then((result) => {
      
            if(result.value){
      
                api.cancelTransaction(id).then((res) => {
      
                  const Toast = window.swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', window.swal.stopTimer)
                      toast.addEventListener('mouseleave', window.swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({icon:'success',title:'A transação foi cancelada.'});
                  getTransactions();
                  
                }).catch(error => {
      
                    if(error !== undefined){
      
                        if(error.response !== undefined){
      
                            if (error.response.data !== undefined){
      
                                if(error.response.data.msg !== undefined){
                                
                                    const Toast = window.swal.mixin({
                                        toast: true,
                                        position: 'top',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', window.swal.stopTimer)
                                            toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                        }
                                    })
                                    
                                    Toast.fire({icon:'error',title:error.response.data.msg});
                                  
                                }
      
                            }
      
                        }
      
                    }
      
                });
      
            }
      
        });
      
    });
     

    var media = 0;
    var mediaValorBitcoin = 0;

    return (
    <React.Fragment>
        <style>{' .fcpad{ padding-left: 60px !important; } '}</style>
        <table className="table align-items-center ">
            <thead>
                <tr>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"></th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"># id</th>
                    <th className="th_cliente text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cliente</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 fcpad"><div className="fcpad">Moeda</div></th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Reais</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Moedas</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Cotação</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Data de pagamento</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Data de envio</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Data do pedido</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Lucro</th>
                    <th></th>
                </tr>
            </thead>
            {

                (loadTransaction === true && loadCoins === true) &&
                <tbody className="listItems">
                    {

                        transactions.map((value,index) => {

                            var coin = coins.find(x => x.coin === value.coin);
                            if(coin === undefined){ coin = {}; }

                            media = media + value.brl;

                            if(value.currentQuote !== null){

                                //mediaValorBitcoin = mediaValorBitcoin + ((value.brl * 1) / value.coin_amount);
                                mediaValorBitcoin = mediaValorBitcoin + value.currentQuote;

                            }
                            

                            return(<tr>
                                        <td className="align-middle text-sm">
                                            {

                                                ((window.user.admin === 1 || window.user.id === value.client) && value.status === "pending") &&
                                                <React.Fragment><button style={{marginBottom:'0px'}} onClick={() => { cancelOrder(value.id); }} className="btn btn-sm bg-gradient-default">Cancelar</button></React.Fragment>

                                            }
                                            {

                                                (value.status === "pending" && value.pix_qrcode_image !== null && value.pix_qrcode_image !== "") &&
                                                <React.Fragment>
                                                <button style={{marginBottom:'0px'}} onClick={() => { setOpenQr(value); }} className="btn btn-sm bg-gradient-default"><i class="fas fa-qrcode" style={{fontSize:'12px'}}></i> Ver código QR</button>
                                                </React.Fragment>

                                            }

                                             
                                            {

                                                (window.user.admin === 1) &&
                                                <React.Fragment>
                                                    <button style={{marginBottom:'0px'}} onClick={() => { 
                                                        
                                                        searchclient(value.client); 
                                                        
                                                        var ds = JSON.parse(JSON.stringify(value));

                                                        if(value.date_paid !== null){ ds.date_paid = moment(ds.date_paid,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'); }
                                                        if(value.date_delivery !== null){ ds.date_delivery = moment(ds.date_delivery,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'); }
                                                        if(value.createDate !== null){ ds.createDate = moment(ds.createDate,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'); }
                                                        
                                                        setDialog(ds);
                                                        
                                                        setOpenModal(true); }} className="btn btn-sm bg-gradient-default">Editar</button> 
                                                    <button className="btn btn-sm bg-gradient-danger ms-2" style={{marginBottom:'0px'}} onClick={() => { remover(value.id); }}>EXCLUIR</button>    
                                                </React.Fragment>

                                            }
                                            
                                        </td>
                                        <td>{index+1} - {value.id}</td>
                                        <td className="td_cliente xlsx_name"><Link to={"/clients/"+value.dataClient.id}>{value.dataClient.name} {value.dataClient.last_name}</Link></td>
                                        <td className="w-30">
                                        <div className="d-flex px-2 py-1 align-items-center">
                                            <img src={coin.icon} className="avatar avatar-sm me-3 border-radius-lg" alt="user1" />
                                            <h6 className="text-sm font-weight-normal mb-0 ">{coin.name}</h6>
                                        </div>
                                        </td>
                                        <td>
                                            {
                                                
                                                (value.type === "buy") &&
                                                <React.Fragment><strong style={{color:'green'}}>Compra</strong></React.Fragment>

                                            }
                                            {
                                                
                                                (value.type === "sell") &&
                                                <React.Fragment><strong style={{color:'red'}}>Venda</strong></React.Fragment>

                                            }
                                        </td>
                                        
                                        <td sc="A1"><React.Fragment>R$ {value.brl}</React.Fragment></td>

                                        <td sc="A2"><React.Fragment>{value.coin_amount} {returnSigla(coin.coin)}</React.Fragment></td>
                                        <td sc="A3">
                                            {

                                                (value.currentQuote !== null) &&
                                                <React.Fragment>R$ {value.currentQuote.toFixed(2)}</React.Fragment>

                                            }
                                        </td>

                                        <td>
                                        {

                                            (value.status === "pending") &&
                                            <React.Fragment><strong style={{color:"red"}}>Pendente</strong></React.Fragment>

                                        }
                                        {

                                            (value.status === "concluded") &&
                                            <React.Fragment><strong style={{color:"green"}}>Concluído</strong></React.Fragment>

                                        }
                                        {

                                            (value.status === "paid") &&
                                            <React.Fragment><strong style={{color:"paid"}}>Pago</strong></React.Fragment>

                                        }
                                        {

                                            (value.status === "canceled") &&
                                            <React.Fragment><strong style={{color:"black"}}>Cancelado</strong></React.Fragment>

                                        }
                                        </td>
                                        <td>
                                            {

                                                (value.date_paid !== null) &&
                                                <React.Fragment>{moment(value.date_paid,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</React.Fragment>

                                            }
                                        </td>
                                        <td>
                                            {

                                                (value.date_delivery !== null) &&
                                                <React.Fragment>{moment(value.date_delivery,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</React.Fragment>

                                            }
                                        </td>
                                        <td>
                                            {

                                                (value.createDate !== null) &&
                                                <React.Fragment>{moment(value.createDate,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</React.Fragment>

                                            }
                                        </td>
                                        <td>
                                            {

                                                (value.profit !== null) &&
                                                <React.Fragment>R$ {value.profit.toFixed(2)}</React.Fragment>

                                            }
                                        </td>
                                         
                                    </tr>)

                        })

                    }
                </tbody>

            }
        </table>


        <div style={{marginLeft:'10px',fontWeight:700}}>
            {

                (transactions.length !== 0) &&
                <React.Fragment>Média das compras: {(media / transactions.length).toFixed(2)}</React.Fragment>

            }
        </div>
        <div style={{marginLeft:'10px',fontWeight:700}}>
            {

                (transactions.length !== 0) &&
                <React.Fragment>Média Valor do Bitcoin: {(mediaValorBitcoin / transactions.length).toFixed(2)}</React.Fragment>

            }
        </div>
        {

            ((loadTransaction === true && loadCoins === true) && transactions.length === 0) &&
            <div style={{textAlign:'center',marginBottom:'10px'}}>Nenhuma transação foi encontrada.</div>
            
        }
        {

            (loadTransaction === false || loadCoins === false) &&
            <React.Fragment><div style={{display:'flex',justifyContent:'center',marginTop:'30px',height:'65px'}}><ClipLoader color="#358fee" size={40} /></div></React.Fragment>

        }
        <Modal className="modal-dialog-centered" isOpen={(openQr.pix_qrcode_image !== undefined ? true:false)} toggle={() => setOpenModal(false)} > 
            <div className="modal-header">
                <h5 className="modal-title"> Código QR para pagamento</h5> 
            </div>
            <div style={{display:'flex',justifyContent:'center'}}>
                <img src={openQr.pix_qrcode_image} alt="..." />
            </div>
            <div className="modal-footer" style={{justifyContent:'center'}}> 
                <Button type="button" onClick={() => setOpenQr({})} > Fechar</Button>
            </div>
        </Modal>

        <Modal className="modal-dialog-centered" style={{maxWidth:'850px'}} isOpen={openModal} toggle={() => setOpenModal(false)} >
            <form onSubmit={(e) => { e.preventDefault(); if(dialog.id === undefined){ postTransaction(); } if(dialog.id !== undefined){ putTransaction(); } }}>
            <div className="modal-header">
                <h5 className="modal-title"> {(dialog.id === undefined ? 'Cadastrar transação':'Editar transação')} </h5> 
            </div>
            <div className="modal-body"> 
                <div className="row">
                        <div className="row">
                            <div className="col-md-3 pt-2">
                                <div className="input-group input-group-static">
                                    <label>Cliente:</label>
                                    <input type="text" className="form-control" value={dialog.client} onChange={(e) => {searchclient(e.target.value); setDialog({...dialog,client:e.target.value}); }} />
                                </div>
                            </div>
                            <div className="col-md-9 pt-2" style={{marginTop:'23px'}}>

                                {

                                    search.map((value,index) => {

                                        return(
                                        <React.Fragment key={"clind_"+value.id}>
                                            <div style={{display:'flex',cursor:'pointer',fontSize:'20px'}} onClick={() => { setSearch([value]); setDialog({...dialog,client:value.id});  }} >
                                                <div style={{marginRight:'10px'}}>
                                                {

                                                    (value.id === dialog.client) &&
                                                    <i className="far fa-check-square"></i>

                                                }
                                                {

                                                    (value.id !== dialog.client) &&
                                                    <i className="far fa-square"></i>

                                                }
                                                </div>
                                                <div>{value.name} {value.last_name}</div>
                                            </div>
                                        </React.Fragment>)

                                    })

                                }

                                {

                                    (search.length === 0) &&
                                    <React.Fragment>
                                        <div style={{display:'flex',fontSize:'20px'}} >
                                            <div style={{marginRight:'10px',width:'100%',textAlign:'center'}}>
                                                <div>Nenhum cliente encontrado.</div>
                                            </div>
                                        </div>
                                    </React.Fragment>

                                }

                            </div>
                        </div>
                        <div div className="col-md-4 pt-2">
                            <div className="input-group input-group-static">
                                <label>Tipo:</label>
                                <select type="text" className="form-control" value={dialog.type} onChange={(e) => { setDialog({...dialog,type:e.target.value}); }} >
                                    <option></option>
                                    <option value="buy">Compra</option>
                                    <option value="sell">Venda</option>
                                </select>
                            </div>
                        </div>
                        <div div className="col-md-4 pt-2">
                            <div className="input-group input-group-static">
                                <label>Moeda:</label>
                                <select type="text" className="form-control" value={dialog.coin} onChange={(e) => { setDialog({...dialog,coin:e.target.value}); }} >
                                    <option></option>
                                    {
                                    
                                        coins.map((value,index) => {

                                            return(<option value={value.coin}>{value.name}</option>)

                                        })
                                    
                                    }
                                </select>
                            </div>
                        </div>
                        <div div className="col-md-4 pt-2">
                            <div className="input-group input-group-static">
                                <label>Status:</label>
                                <select type="text" className="form-control" value={dialog.status} onChange={(e) => { setDialog({...dialog,status:e.target.value}); }} >
                                    <option></option>
                                    <option value="pending">Pendente</option>
                                    <option value="paid">Pago</option>
                                    <option value="concluded">Concluído</option>
                                    <option value="canceled">Cancelado</option>
                                </select>
                            </div>
                        </div>

                        {

                            (dialog.type === "buy") &&
                            <React.Fragment>
                                <div className="col-md-4 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Valor da compra em reais:</label>
                                        <input type="text" className="form-control" value={dialog.brl} onChange={(e) => { setDialog({...dialog,brl:e.target.value}); }} />
                                    </div>
                                </div>
                                <div className="col-md-4 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Cotação da compra:</label>
                                        <input type="text" className="form-control" value={dialog.currentQuote} onChange={(e) => { setDialog({...dialog,currentQuote:e.target.value}); }} />
                                    </div>
                                </div>
                                <div div className="col-md-4 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Quantidade de moedas solicitadas:</label>
                                        <input type="text" className="form-control" value={dialog.coin_amount} onChange={(e) => { setDialog({...dialog,coin_amount:e.target.value}); }} />
                                    </div>
                                </div>
                                <div className="col-md-4 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Lucro em reais reais:</label>
                                        <input type="text" className="form-control" value={dialog.profit} onChange={(e) => { setDialog({...dialog,profit:e.target.value}); }} />
                                    </div>
                                </div>
                            </React.Fragment>

                        }
                        {

                            (dialog.type === "sell") &&
                            <React.Fragment>
                                 <div div className="col-md-6 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Quantidade de moedas solicitadas:</label>
                                        <input type="text" className="form-control" value={dialog.coin_amount} onChange={(e) => { setDialog({...dialog,coin_amount:e.target.value}); }} />
                                    </div>
                                </div>
                                <div className="col-md-6 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Valor em reais pago:</label>
                                        <input type="text" className="form-control" value={dialog.brl} onChange={(e) => { setDialog({...dialog,brl:e.target.value}); }} />
                                    </div>
                                </div>
                                <div className="col-md-4 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Lucro em reais reais:</label>
                                        <input type="text" className="form-control" value={dialog.profit} onChange={(e) => { setDialog({...dialog,profit:e.target.value}); }} />
                                    </div>
                                </div>
                            </React.Fragment>

                        }

                        <div className="col-md-4 pt-4">
                            <div className="input-group input-group-static">
                                <label>Data de pagamento:</label>
                                <InputMask value={dialog.date_paid} onChange={(e) => { setDialog({...dialog,date_paid:e.target.value}); }} className="form-control" mask="99/99/9999 99:99:99" maskChar=" "  />
                            </div>
                            <div><font onClick={() => { setDialog({...dialog,date_paid:moment().format('DD/MM/YYYY HH:mm:ss')}); }} className="btn btn-sm" style={{marginBottom:'0px',position:"absolute", padding:'0px'}}>Agora</font></div>
                        </div>
                        

                        <div className="col-md-4 pt-4">
                            <div className="input-group input-group-static">
                                <label>Data de envio:</label>
                                <InputMask value={dialog.date_delivery} onChange={(e) => { setDialog({...dialog,date_delivery:e.target.value}); }} className="form-control" mask="99/99/9999 99:99:99" maskChar=" "  />
                            </div>
                            <div><font onClick={() => { setDialog({...dialog,date_delivery:moment().format('DD/MM/YYYY HH:mm:ss')}); }} className="btn btn-sm" style={{marginBottom:'0px',position:"absolute", padding:'0px'}}>Agora</font></div>
                        </div>

                        <div className="col-md-4 pt-4">
                            <div className="input-group input-group-static">
                                <label>Data do pedido:</label>
                                <InputMask value={dialog.createDate} onChange={(e) => { setDialog({...dialog,createDate:e.target.value}); }} className="form-control" mask="99/99/9999 99:99:99" maskChar=" "  />
                            </div>
                            <div><font onClick={() => { setDialog({...dialog,createDate:moment().format('DD/MM/YYYY HH:mm:ss')}); }} className="btn btn-sm" style={{marginBottom:'0px',position:"absolute", padding:'0px'}}>Agora</font></div>
                        </div>

                        {

                            (dialog.type === "sell") &&
                            <React.Fragment>
                                <div className="col-md-12 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Banco:</label>
                                        <Select options={bancos} disabled={dialog.bank} style={{width:'300px',flex:'inherit',textAlign:'center',fontWeight:'100',fontSize:'15px'}} type="text" className="form-control" onChange={(value) => { setDialog({...dialog,bank:value.value}); }} value={bancos.find(x => x.value === dialog.bank) } />
                                    </div>
                                </div>
                                <div className="col-md-6 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Agencia:</label>
                                        <input type="text" className="form-control" value={dialog.ag} onChange={(e) => { setDialog({...dialog,ag:e.target.value}); }} />
                                    </div>
                                </div>
                                <div className="col-md-6 pt-4">
                                    <div className="input-group input-group-static">
                                        <label>Conta:</label>
                                        <input type="text" className="form-control" value={dialog.cc} onChange={(e) => { setDialog({...dialog,cc:e.target.value}); }} />
                                    </div>
                                </div>
                            </React.Fragment>

                        }
                                
                        <div className="col-md-12 pt-4">
                            <div className="input-group input-group-static">
                                <label>Carteira:</label>
                                <input type="text" className="form-control" value={dialog.wallet} onChange={(e) => { setDialog({...dialog,wallet:e.target.value}); }} />
                            </div>
                        </div>
                        
                    </div> 
                </div>
                <div className="modal-footer"> 
                    {

                        (dialog.id !== undefined && dialog.status === "pending") &&
                        <React.Fragment>
                            <Button type="button" color="success" onClick={() => {
                                
                                setDialog({...dialog,status:"paid",date_paid:moment().format('DD/MM/YYYY HH:mm:ss')});

                            }} > Marcar como pago</Button>
                        </React.Fragment>

                    }

                    {

                        (dialog.id !== undefined && dialog.status === "paid") &&
                        <React.Fragment>
                            <Button type="button" color="success" onClick={() => {
                                
                                setDialog({...dialog,status:"concluded",date_delivery:moment().format('DD/MM/YYYY HH:mm:ss')});

                            }} > CONCLUIR</Button>
                        </React.Fragment>

                    }

                    <Button type="button" onClick={() => setOpenModal(false)} > Fechar</Button>
                    <Button type="submit" color="primary" >{(dialog.id === undefined ? 'Cadastrar transação':'Salvar alterações')}</Button>
                </div>
            </form>
        </Modal>
 
    <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={openFilter}
        title="Filtro de tranções"
        subtitle=""
        onRequestClose={() => { setOpenFilter(false); }}
        width={400}
     >
 
        <form onSubmit={(e) => { e.preventDefault(); if(loadTransaction === false){ return false; } getTransactions(); }}>
            <div>
                <div>Data inicial:</div>
                <div><InputMask disabled={(loadTransaction !== false ? false:true)} value={startDate} onChange={(e) => { setStartDate(e.target.value); }} className="form-control inputfilter" mask="99/99/9999" maskChar=" "  /></div>
            </div>
            <div>
                <div>Data final:</div>
                <div><InputMask disabled={(loadTransaction !== false ? false:true)} value={endDate} onChange={(e) => { setEnd(e.target.value); }} className="form-control inputfilter" mask="99/99/9999" maskChar=" "  /></div>
            </div>
            <div>
                <div>Status:</div>
                <div>
                <select disabled={(loadTransaction !== false ? false:true)} value={statusFilter} onChange={(e) => { setStatusFilter(e.target.value); }} type="text" class="form-control inputfilter">
                    <option value="">Todos</option>
                    <option value="pending">Pendente</option>
                    <option value="paid">Pago</option>
                    <option value="concluded">Concluído</option>
                    <option value="canceled">Cancelado</option>
                </select>
                </div>
            </div> 
            <div>
                {

                    (loadTransaction === false) &&
                    <div style={{display:'flex',justifyContent:'center'}}><ClipLoader color="#358fee" size={40} /></div>

                }
                {

                    (loadTransaction === true) &&
                    <button type="submit" className="btn btn-success" style={{width:'100%'}}>Filtrar transações</button>

                }
            </div>
        </form>
        <br /> 
    </SlidingPane>
     

    </React.Fragment>)


}