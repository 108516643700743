import React,{useState,useEffect} from "react";
import api from "./../../Api.js";
import ClipLoader from "react-spinners/ClipLoader";
import {Button,Modal} from "reactstrap";
import Editor from './componenets/Editor.jsx';

export default function Template(){

    const [load,setLoad] = useState(false);

    const [openModal,setOpenModal] = useState(false);
    const [dialog,setDialog] = useState({});
    const [templates,setTemplates] = useState([]);
 
    const getTemplates = (async () => {

        setLoad(false);
        api.getTemplates().then(async (res) => { 

            setLoad(true);
            setTemplates(res.data);

        }).catch((error) => {

            setLoad(false);

        });

    });
 
    const remover = ((id) => {

        window.swal.fire({
            position:"center",
            toast: true,
            title: 'Você deseja remover o template?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar"
        }).then((result) => {
      
            if(result.value){
      
                api.deleteTemplate(id).then((res) => {
      
                  const Toast = window.swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', window.swal.stopTimer)
                      toast.addEventListener('mouseleave', window.swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({icon:'success',title:'O template foi removido.'});
                  getTemplates();
                  
                }).catch(error => {
      
                    if(error !== undefined){
      
                        if(error.response !== undefined){
      
                            if (error.response.data !== undefined){
      
                                if(error.response.data.msg !== undefined){
                                
                                    const Toast = window.swal.mixin({
                                        toast: true,
                                        position: 'top',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', window.swal.stopTimer)
                                            toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                        }
                                    })
                                    
                                    Toast.fire({icon:'error',title:error.response.data.msg});
                                  
                                }
      
                            }
      
                        }
      
                    }
      
                });
      
            }
      
        });
      
    });
       
    const postTemplate = (() => {

        console.log('postTemplate');
        var obj = JSON.parse(JSON.stringify(dialog));
        console.log(obj);
        
        api.postTemplate(obj).then(async (res) => { 

            setDialog(res.data);
            setOpenModal(false);
            getTemplates();

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'success',title:'O template foi cadastrado.'});
            
        }).catch((error) => {

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:error.response.data.msg});

        });

    });

    const putTemplate = (() => {

        console.log('putTemplate');
        var obj = JSON.parse(JSON.stringify(dialog)); 
 
        
        api.putTemplate(obj.id,obj).then(async (res) => { 

            setDialog(res.data);
            setOpenModal(false);
            getTemplates();
            
            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'success',title:'As alterações foram salvas.'});
            
        }).catch((error) => {

            console.error(error);
            const Toast = window.swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            })
            
            Toast.fire({icon:'warning',title:error.response.data.msg});

        });

    });

    useEffect(() => {

        getTemplates();
        return (() => { });

    },[]);

    return (<React.Fragment>
                
        <div className="container-fluid py-4">
            <div className="d-sm-flex justify-content-between">
                <div></div>
                <div className="d-flex"> 
                    <button onClick={() => { setOpenModal(true); setDialog({html:"",description:"",name:"",subject:""}); }}className="btn btn-icon bg-gradient-success ms-2"><i className="fas fa-plus"></i> Cadastrar template</button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Templates de e-mail</h5>
                        </div>
                        <div className="table-responsive">
                            <table className="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">#</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Nome</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Assunto</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Descrição</th>
                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        templates.map((value,index) => {
  
                                            return( <tr key={'id_'+value.id}>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.id}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.name}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.subject}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-sm font-weight-normal mb-0">{value.description}</p>
                                                        </td>
                                                        <td>
                                                            <button style={{marginBottom:'0px'}} onClick={() => { setOpenModal(true); setDialog(value); }} className="btn btn-sm bg-gradient-default ">Editar</button> 
                                                            <button style={{marginBottom:'0px'}} onClick={() => remover(value.id)} className="btn btn-sm bg-gradient-danger ms-2">Remover</button>
                                                        </td>
                                                    </tr> )

                                        })

                                    }
                                </tbody>
                            </table>
                            {

                                (load === true && templates.length === 0) &&
                                <React.Fragment><div style={{textAlign:'center',padding:'20px'}}>Nenhum template foi cadastrado.</div></React.Fragment>

                            }
                            {

                                (load === false) &&
                                <React.Fragment>
                                    <div style={{display:'flex',justifyContent:'center',margin:'50px 0px'}}><ClipLoader color="#358eed"/></div>
                                </React.Fragment>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal className="modal-dialog-centered" isOpen={openModal} toggle={() => setOpenModal(false)} >
            <form onSubmit={(e) => { e.preventDefault(); if(dialog.id === undefined){ postTemplate(); } if(dialog.id !== undefined){ putTemplate(); } }}>
                <div className="modal-header">
                    <h5 className="modal-title"> {(dialog.id === undefined ? 'Cadastrar template':'Editar template')} </h5> 
                </div>
                <div className="modal-body"> 
                    <div className="row">
                        <div className="col-md-12 pt-2">
                            <div className="input-group input-group-static">
                                <label>Nome</label>
                                <input type="text" className="form-control" value={dialog.name} onChange={(e) => { setDialog({...dialog,name:e.target.value}); }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 pt-2">
                        <div className="input-group input-group-static">
                            <label>Assunto</label>
                            <input type="text" className="form-control" value={dialog.subject} onChange={(e) => { setDialog({...dialog,subject:e.target.value}); }} />
                        </div>
                    </div>
                    <div className="col-md-12 pt-2">
                        <div className="input-group input-group-static">
                            <label>Descrição</label>
                            <textarea type="text" className="form-control" value={dialog.description} onChange={(e) => { setDialog({...dialog,description:e.target.value}); }} />
                        </div>
                    </div>
                    
                    <div div className="col-md-12 pt-2">
                        
                        <div>Variáveis disponíves:</div>
                        <div>[[name]] [[last_name]] [[bank_name]] [[cpf]] [[phone]] [[number]] [[zip_vode]] [[street]] [[city]] [[state]] [[email]]</div>
                        <br/>
                        <Editor 
                            html={dialog.html} 
                            onChange={(html) => { 

                                console.log('html');
                                console.log(html);

                                dialog.html = html;
                                setDialog({...dialog,html:html});
                            
                            }}
                        />
                        
                    </div>
                </div>
                <div className="modal-footer">
                    <Button type="button" onClick={() => setOpenModal(false)} > Fechar</Button>
                    <Button type="submit" color="primary" >{(dialog.id === undefined ? 'Cadastrar template':'Salvar alterações')}</Button>
                </div>
            </form>
        </Modal>
    </React.Fragment>)

}