import React,{useState,useEffect} from "react";
import api from "./../../Api.js";
import ClipLoader from "react-spinners/ClipLoader";

export default function SendMail(props){

    const [load,setLoad] = useState(false);
    const [loadSend,setLoadSend] = useState(false);

    const [templates,setTemplates] = useState([]);

    const getTemplates = (async () => {

        setLoad(false);
        api.getTemplates().then(async (res) => { 

            setLoad(true);
            setTemplates(res.data);

        }).catch((error) => {

            setLoad(false);

        });

    });


    const sendMail = (async (id) => {

        if(loadSend === true) return false;
        setLoadSend(true);

        var obj = {template:id,client:props.client};

        api.sendMail(obj).then(async (res) => {

            const Toast = window.swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', window.swal.stopTimer)
                  toast.addEventListener('mouseleave', window.swal.resumeTimer)
                }
            });
              
            Toast.fire({icon:'success',title:'O e-mail foi enviado.'});
            setLoadSend(false);
 
        }).catch(error => {
            
            setLoadSend(false);

            if(error !== undefined){

                if(error.response !== undefined){

                    if (error.response.data !== undefined){

                        if(error.response.data.msg !== undefined){
                        
                          const Toast = window.swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', window.swal.stopTimer)
                                    toast.addEventListener('mouseleave', window.swal.resumeTimer)
                                }
                            })
                            
                            Toast.fire({icon:'warning',title:error.response.data.msg});
                                        
                        }

                    }

                }

            }

        });

    });

    useEffect(() => {

        getTemplates();
        return (() => { });

    },[]);

    return <React.Fragment>

        <table className="table align-items-center mb-0" style={{overflowY:'clip'}}>
            <thead>
                <tr>
                    <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Nome</th>
                    <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Descrição</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {

                templates.map((value,index) => {

                    return( <tr key={'id_'+value.id}>
                              
                                <td>
                                    <p className="text-sm font-weight-normal mb-0">{value.name}</p>
                                </td>
                                <td>
                                    <p className="text-sm font-weight-normal mb-0">{value.description}</p>
                                </td>
                                <td>
                                    <button style={{marginBottom:'0px'}} onClick={() => {

                                        sendMail(value.id)

                                    }} className="btn btn-sm bg-gradient-primary ms-2">Enviar e-mail</button>
                                </td>
                            </tr> )

                })

            }
            </tbody>
        </table>

        {

            (load === true && templates.length === 0) &&
            <React.Fragment><div style={{textAlign:'center',padding:'20px'}}>Nenhum template foi cadastrado.</div></React.Fragment>

        }

        {

            (load === false || loadSend === true) &&
            <center style={{marginTop:'15px'}}><ClipLoader/></center>

        }

    </React.Fragment>

}