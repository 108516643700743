import axios from "axios";

const api = axios.create({ secure: false });


const getTokens = (() => {

    return {session:window.cookies.get("session")};

})


if(window.location.host === 'localhost:3000'){

    window.backend = "http://localhost:3001";

}else{

    window.backend = "https://api.cli.armataex.com";

}


//olá
var apis = {

    getConfiguration: (data) => api.get(window.backend+'/configuration',data),

    resetpassword: (data) => api.put(window.backend+'/auth/resetpassword',data),
    verifycoderecover: (code) => api.post(window.backend+'/auth/verifycoderecover/'+code),
    recoverpassword: (email) => api.post(window.backend+'/auth/recoverpassword/'+email),

    postClient: (data) => api.post(window.backend+'/client',data,{headers:getTokens()}),
    register: (data) => api.post(window.backend+'/register',data,{headers:getTokens()}),
    putClient: (id,data) => api.put(window.backend+'/client/'+id,data,{headers:getTokens()}),
    getClient: (id) => api.get(window.backend+'/client/'+id,{headers:getTokens()}),
    deleteClient: (id) => api.delete(window.backend+'/client/'+id,{headers:getTokens()}),

    postCoin: (data) => api.post(window.backend+'/coin',data,{headers:getTokens()}),
    putCoin: (id,data) => api.put(window.backend+'/coin/'+id,data,{headers:getTokens()}),
    getCoin: (id) => api.get(window.backend+'/coin/'+id,{headers:getTokens()}),
    getCoins: (id) => api.get(window.backend+'/coin',{headers:getTokens()}),
    getAllCoins: (id) => api.get(window.backend+'/coinall',{headers:getTokens()}),
    deleteCoin: (id) => api.delete(window.backend+'/coin/'+id,{headers:getTokens()}),


    userPostTransation: (obj) => api.post(window.backend+'/usertransation/',obj,{headers:getTokens()}),
     
    getCoinsValue: (id) => api.get(window.backend+'/coingecko/'+id,{headers:getTokens()}),
    getTransactions: (extra) => api.get(window.backend+'/transaction'+extra,{headers:getTokens()}),
     

    postTransaction: (data) => api.post(window.backend+'/transaction',data,{headers:getTokens()}),
    putTransaction: (id,data) => api.put(window.backend+'/transaction/'+id,data,{headers:getTokens()}),
    getTransaction: (id) => api.get(window.backend+'/transaction/'+id,{headers:getTokens()}),
    cancelTransaction: (id) => api.put(window.backend+'/canceltransaction/'+id,{},{headers:getTokens()}),

    getTransactionsClient: (id) => api.get(window.backend+'/transaction/client/'+id,{headers:getTokens()}),
     
    deleteTransaction: (id) => api.delete(window.backend+'/transaction/'+id,{headers:getTokens()}),

    searchclient: (search) => api.get(window.backend+'/searchclient?search='+search,{headers:getTokens()}),


    auth: (data) => api.post(window.backend+'/auth',data,{headers:getTokens()}),
    logout: (data) => api.get(window.backend+'/logout',{headers:getTokens()}),

    verify: () => api.get(window.backend+'/verify',{headers:getTokens()}),
    getClients: () => api.get(window.backend+'/client',{headers:getTokens()}),
    cep: (cep) => api.get(window.backend+'/getcep/'+cep,{headers:getTokens()}),
 
    getClientReport: (start,end) => api.get(window.backend+'/report/clients?startDate='+start+'&endDate='+end,{headers:getTokens()}),
    getInvoiceReport: (start,end) => api.get(window.backend+'/report/pedidos?startDate='+start+'&endDate='+end,{headers:getTokens()}),
     
    postPurchase: (data) => api.post(window.backend+'/purchase',data,{headers:getTokens()}),
    putPurchase: (id,data) => api.put(window.backend+'/purchase/'+id,data,{headers:getTokens()}),
    getPurchase: (id) => api.get(window.backend+'/purchase/'+id,{headers:getTokens()}),
    listPurchase: (id) => api.get(window.backend+'/purchase/',{headers:getTokens()}),
    deletePurchase: (id) => api.get(window.backend+'/purchase/'+id,{headers:getTokens()}),



    postTemplate: (data) => api.post(window.backend+'/template',data,{headers:getTokens()}),
    putTemplate: (id,data) => api.put(window.backend+'/template/'+id,data,{headers:getTokens()}),
    getTemplate: (id) => api.get(window.backend+'/template/'+id,{headers:getTokens()}),
    getTemplates: (id) => api.get(window.backend+'/template',{headers:getTokens()}),
    deleteTemplate: (id) => api.delete(window.backend+'/template/'+id,{headers:getTokens()}),

    sendMail: (obj) => api.post(window.backend+'/templatesend/',obj,{headers:getTokens()}),

     

}

window.api = apis;

export default apis;